import { FC, PropsWithChildren, useCallback, useMemo } from 'react'

import { useGenerateUserDetailsDownloadUrlsApi } from 'api/attachments/queries/useGenerateUserDetailsDownloadUrlsApi'
import { useProject } from 'hooks/useProject'
import { ResponsibleUser, useSearchUsers } from 'pages/project/components/canvas/components/selectPerson/utils'
import { SelectPersonDropdown } from 'pages/project/components/canvas/components/selectPersonDropdown/SelectPersonDropdown'
import styles from 'pages/project/components/canvas/components/selectPersonInline/SelectPersonInline.module.scss'
import { excludeFalsy } from 'utils/common'

interface Props {
  selectedId?: string
  projectId?: string
  onChange: (person: ResponsibleUser) => void
}

export const SelectPersonInline: FC<PropsWithChildren<Props>> = ({
  selectedId,
  projectId,
  children,
  onChange,
}): JSX.Element => {
  const context = useProject()

  const { search, usersOptions, isUsersLoading, setUsersSearch } = useSearchUsers({
    projectId: context?.project.id ?? projectId,
  })

  const isEmpty = !!search.length && !usersOptions.length && !isUsersLoading

  const avatarsKeys = useMemo(
    () => Array.from(new Set(usersOptions.map(user => user.avatarThumbnail?.key).filter(excludeFalsy))),
    [usersOptions],
  )

  const { data: avatars } = useGenerateUserDetailsDownloadUrlsApi({
    params: { keys: avatarsKeys },
    enabled: !!avatarsKeys.length,
  })

  const avatarsCache = useMemo(
    () => Object.fromEntries(avatars.map(({ key, signed_url }) => [key, signed_url])),
    [avatars],
  )

  const onCloseHandle = useCallback(() => {
    setUsersSearch('')
  }, [setUsersSearch])

  return (
    <SelectPersonDropdown
      selectedId={selectedId}
      options={usersOptions}
      avatarsCache={avatarsCache}
      isLoading={isUsersLoading}
      isEmpty={isEmpty}
      onSearchValueChange={setUsersSearch}
      onSelect={onChange}
      onClose={onCloseHandle}
    >
      <label className={styles.personLabel}>{children}</label>
    </SelectPersonDropdown>
  )
}
