import {
  WppActionButton,
  WppButton,
  WppIconReset,
  WppTypography,
  WppSegmentedControl,
  WppSegmentedControlItem,
} from '@platform-ui-kit/components-library-react'
import { HierarchyLevelType, HierarchyNode, NavigationTreeNode } from '@wpp-open/core'
import { HierarchyContainerNodeId, HierarchyCustomNodeType } from '@wpp-open/core/types/mapping/common'
import { useOs } from '@wpp-open/react'
import { FC, useMemo } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { FormSelect, FormSelectOption } from 'components/form/formSelect/FormSelect'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { useForm } from 'hooks/form/useForm'
import { useHierarchy } from 'hooks/useHierarchy'
import { getAppliedFilters } from 'pages/dashboard/components/utils'
import { initialProjectFilters } from 'pages/dashboard/Dashboard'
import styles from 'pages/dashboard/Dashboard.module.scss'
import { ProjectFilter, ProjectType, ProjectStatus, ProjectOwnership } from 'types/projects/projects'
import { capitalizeFirstLetter } from 'utils/common'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  filter: ProjectFilter

  handleCloseModal(filter?: ProjectFilter): void
}

const projectTypes = [
  { value: ProjectType.BLANK, label: 'Blank' },
  { value: ProjectType.PITCH, label: 'Pitch' },
  { value: ProjectType.WORKSHOP, label: 'Workshop' },
  { value: ProjectType.CAMPAIGN, label: 'Campaign' },
]

const mapToOptions = (nodes: NavigationTreeNode[] = []): FormSelectOption[] => {
  const mapValues = nodes.reduce((previousValue: Record<string, string[]>, currentValue: NavigationTreeNode) => {
    const { name } = currentValue

    if (!name) return previousValue
    return { ...previousValue, [name]: [...(previousValue[name] || []), currentValue.azId] as string[] }
  }, {})

  return Object.keys(mapValues)
    .map(key => ({ value: mapValues[key].join(','), label: key }))
    .sort((a, b) => a.label?.localeCompare(b.label))
}

const ProjectFilterModal: FC<Props> = ({ isOpen, onClose, onCloseComplete, handleCloseModal, filter }) => {
  const { t } = useTranslation()
  const { osContext } = useOs()

  const { mapping } = osContext.navigationTree
  const { hierarchyLevels } = osContext.tenant
  const { hierarchyOrder } = useHierarchy()

  const form = useForm({ defaultValues: filter })

  const {
    handleSubmit,
    formState: { isSubmitting },
    watch,
    reset,
    setValue,
    getValues,
  } = form

  const mappedTree = useMemo(
    () =>
      Object.keys(mapping).reduce((previousValue, currentValue) => {
        const element = mapping[currentValue]

        const type = element.type === HierarchyCustomNodeType ? element.customTypeName : element.type

        // @ts-ignore
        return { ...previousValue, [type]: [...(previousValue[type] || []), element] }
      }, {} as Record<HierarchyLevelType, NavigationTreeNode[]>),
    [mapping],
  )

  const options = useMemo(() => {
    return Object.entries(mappedTree).reduce((prev, [key, nodes]) => {
      return { ...prev, [key.toLowerCase()]: mapToOptions(nodes) }
    }, {} as Record<HierarchyLevelType, FormSelectOption[]>)
  }, [mappedTree])

  const statusOptions = [
    { value: ProjectStatus.ACTIVE, label: t('project.status.active') },
    { value: ProjectStatus.ARCHIVED, label: t('project.status.archived') },
    { value: ProjectStatus.COMPLETED, label: t('project.status.completed') },
  ]

  const allowNavigationTypes = useMemo(() => osContext.tenant.hierarchyLevels.map(lvl => lvl.type), [osContext])

  const onSubmit = handleSubmit(value => {
    const { children } = mapping[HierarchyContainerNodeId]

    const isWorkspaceEmpty = Object.entries(value)
      .filter(([key]) => hierarchyOrder.includes(key))
      .some(([, value]) => !!value.length)

    if (!isWorkspaceEmpty) {
      handleCloseModal({ ...value, workspace: undefined })
      onClose()
      return
    }

    const getType = (node: HierarchyNode) => (node.type === HierarchyCustomNodeType ? node.customTypeName : node.type)!

    const getAllChildren = (nodes: string[], isParentSelected = false) => {
      return nodes.reduce((acc: string[], curNodeId: string): string[] => {
        const currentNode = mapping[curNodeId] as HierarchyNode
        const currentNodeChildren = currentNode?.children || []

        // We need only HierarchyLevelType projects
        if (!allowNavigationTypes.includes(getType(currentNode))) return acc

        // Get selected nodes from form value
        const selectedNodes = ((value[getType(currentNode).toLowerCase() as keyof ProjectFilter] as string[]) || [])
          .map(el => el.split(','))
          .flat(Infinity) as string[]

        const checkIfNodeHasSelectedChildren = (currentHierarchyIndex: number) => {
          const selectedOnNextLvl = []
          for (let index = currentHierarchyIndex + 1; index < hierarchyLevels.length; index++) {
            const lvl = hierarchyLevels[index].type
            const nextLvlValue = value[lvl.toLowerCase() as keyof ProjectFilter]

            selectedOnNextLvl.push(!!nextLvlValue?.length)
          }

          return selectedOnNextLvl.some(Boolean)
        }

        const currentHierarchyIndex = hierarchyLevels.findIndex(h => h.type === getType(currentNode))
        const nodeHasSelectedChildren = checkIfNodeHasSelectedChildren(currentHierarchyIndex)

        if (!!selectedNodes.length) {
          const isThisNodeSelected = selectedNodes.includes(currentNode.azId)

          if (isThisNodeSelected) {
            if (!!currentNodeChildren.length) {
              return acc.concat(
                nodeHasSelectedChildren ? [] : currentNode.azId,
                getAllChildren(currentNodeChildren, true),
              )
            } else {
              if (currentHierarchyIndex === hierarchyLevels.length - 1) return acc.concat(currentNode.azId)
              return nodeHasSelectedChildren ? acc : acc.concat(currentNode.azId)
            }
          } else return acc
        } else {
          if (!!currentNodeChildren.length) {
            return isParentSelected
              ? acc.concat(
                  nodeHasSelectedChildren ? [] : currentNode.azId,
                  getAllChildren(currentNodeChildren, isParentSelected),
                )
              : acc.concat(getAllChildren(currentNodeChildren, isParentSelected))
          } else {
            if (currentHierarchyIndex >= hierarchyLevels.length - 1)
              return isParentSelected ? acc.concat(currentNode.azId) : acc

            return nodeHasSelectedChildren ? acc : acc.concat(currentNode.azId)
          }
        }
      }, [])
    }

    handleCloseModal({ ...value, workspace: getAllChildren(children) })
    onClose()
  })

  const watchAllFields = watch()

  const isStatusDirty = watchAllFields.status?.some(
    (value: keyof typeof ProjectStatus) => value !== ProjectStatus.ACTIVE,
  )
  const isOwnershipDirty = getValues('ownership') !== ProjectOwnership.ALL

  const onReset = () =>
    reset({
      ...initialProjectFilters,
      ...hierarchyOrder.reduce(
        (pre, curr) => ({
          ...pre,
          [curr]: [],
        }),
        {},
      ),
    })

  const appliedFilters = (filter: ProjectFilter) => {
    const { status, ownership, ...rest } = filter

    return getAppliedFilters(rest)
  }

  return (
    <>
      <FormProvider {...form}>
        <SideModal
          open={isOpen}
          formConfig={{ onSubmit }}
          onWppSideModalClose={() => {
            handleCloseModal()
            onClose()
          }}
          onWppSideModalCloseComplete={onCloseComplete}
          size="s"
          data-testid="create-project-modal"
        >
          <WppTypography slot="header" type="2xl-heading" data-testid="filters-header">
            {t('modals.dashboard_filters.title')!}
          </WppTypography>
          <Flex slot="body" direction="column" gap={24}>
            <WppSegmentedControl
              size="s"
              onWppChange={event => setValue('ownership', event.detail.value as ProjectOwnership)}
              value={getValues('ownership') ?? ProjectOwnership.ALL}
              data-testid="dashboard-project-ownership-filter"
            >
              <WppSegmentedControlItem value={ProjectOwnership.ALL}>
                {t('dashboard.field_my_project_label_all')!}
              </WppSegmentedControlItem>
              <WppSegmentedControlItem value={ProjectOwnership.MY}>
                {t('dashboard.field_my_project_label_my')!}
              </WppSegmentedControlItem>
              <WppSegmentedControlItem value={ProjectOwnership.SHARED_WITH_ME}>
                {t('dashboard.field_my_project_label_shared')!}
              </WppSegmentedControlItem>
            </WppSegmentedControl>
            <FormSelect
              name="status"
              type="multiple"
              data-testid="status-select"
              options={statusOptions}
              labelConfig={{ text: t('modals.dashboard_filters.field_status_label') }}
              placeholder={t('modals.dashboard_filters.field_status_placeholder')!}
              withFolder
              required
            />
            <FormSelect
              name="type"
              type="multiple"
              data-testid="type-select"
              options={projectTypes}
              labelConfig={{ text: t('modals.dashboard_filters.field_type_label') }}
              placeholder={t('modals.dashboard_filters.field_type_placeholder')!}
              withFolder
              required
            />
            {hierarchyOrder?.map(hierarchy => {
              return (
                <FormSelect
                  key={hierarchy}
                  name={hierarchy}
                  withSearch
                  className={styles.hierarchySelect}
                  type="multiple"
                  data-testid={`${hierarchy.toLowerCase()}-select`}
                  options={options?.[hierarchy as HierarchyLevelType] || []}
                  labelConfig={{
                    text: t(`modals.dashboard_filters.field_${hierarchy.toLowerCase()}_label`, {
                      defaultValue: capitalizeFirstLetter(hierarchy),
                    }),
                  }}
                  placeholder={
                    t(`modals.create_project.field_${hierarchy.toLowerCase()}_placeholder`, {
                      defaultValue: capitalizeFirstLetter(hierarchy),
                    })!
                  }
                  withFolder
                  required
                />
              )
            })}
          </Flex>
          <Flex justify="between" slot="actions">
            <Flex>
              {(appliedFilters(watchAllFields) > 0 || isStatusDirty || isOwnershipDirty) && (
                <WppActionButton variant="primary" onClick={onReset}>
                  <WppIconReset className={styles.resetIcon} />
                  {t('common.btn_reset')}
                </WppActionButton>
              )}
            </Flex>
            <Flex gap={12}>
              <WppButton variant="secondary" size="m" onClick={onClose}>
                {t('modals.dashboard_filters.btn_cancel')}
              </WppButton>
              <WppButton variant="primary" size="m" type="submit" loading={isSubmitting}>
                {t('common.btn_apply')}
              </WppButton>
            </Flex>
          </Flex>
        </SideModal>
      </FormProvider>
    </>
  )
}

export const { showModal: showProjectFilterModal } = createNiceModal<Props>(ProjectFilterModal, 'project-filter-modal')
