// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NqyF2{box-sizing:border-box;height:100%;margin:32px 38px;padding-bottom:60px}.NqyF2::part(card){height:calc(100% - 64px)}.q7mvt{color:var(--wpp-grey-color-1000)}.RE2e6{color:var(--wpp-grey-color-800)}.Elvy4{display:inline-flex;align-items:center}.Kj0ix{overflow:hidden;text-overflow:ellipsis}.pdURT{flex-shrink:0}.bTUA4{line-height:normal}.c7rft::part(card){background:var(--wpp-grey-color-100);box-shadow:none}.LiZh0{--ag-background-color: var(--wpp-grey-color-100)}.LiZh0 .ag-root.ag-unselectable .ag-header{border-bottom:none;--ag-header-background-color: var(--wpp-grey-color-100)}`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/components/projectsTableView/ProjectsTableVire.module.scss"],"names":[],"mappings":"AAAA,OACE,qBAAA,CACA,WAAA,CACA,gBAAA,CACA,mBAAA,CAEA,mBACE,wBAAA,CAIJ,OACE,gCAAA,CAGF,OACE,+BAAA,CAGF,OACE,mBAAA,CACA,kBAAA,CAGF,OACE,eAAA,CACA,sBAAA,CAGF,OACE,aAAA,CAGF,OACE,kBAAA,CAIA,mBACE,oCAAA,CACA,eAAA,CAKF,OAOE,gDAAA,CALE,2CACE,kBAAA,CACA,uDAAA","sourcesContent":[".card {\n  box-sizing: border-box;\n  height: 100%;\n  margin: 32px 38px;\n  padding-bottom: 60px;\n\n  &::part(card) {\n    height: calc(100% - 64px);\n  }\n}\n\n.notDefined {\n  color: var(--wpp-grey-color-1000);\n}\n\n.grey800 {\n  color: var(--wpp-grey-color-800);\n}\n\n.cell {\n  display: inline-flex;\n  align-items: center;\n}\n\n.overflow {\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.noShrink {\n  flex-shrink: 0;\n}\n\n.avatarCell {\n  line-height: normal;\n}\n\n.emptyCard {\n  &::part(card) {\n    background: var(--wpp-grey-color-100);\n    box-shadow: none;\n  }\n}\n\n.hideBorder {\n  :global {\n    .ag-root.ag-unselectable {\n      .ag-header {\n        border-bottom: none;\n        --ag-header-background-color: var(--wpp-grey-color-100);\n      }\n    }\n    --ag-background-color: var(--wpp-grey-color-100);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `NqyF2`,
	"notDefined": `q7mvt`,
	"grey800": `RE2e6`,
	"cell": `Elvy4`,
	"overflow": `Kj0ix`,
	"noShrink": `pdURT`,
	"avatarCell": `bTUA4`,
	"emptyCard": `c7rft`,
	"hideBorder": `LiZh0`
};
export default ___CSS_LOADER_EXPORT___;
