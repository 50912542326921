import styles from 'components/svg/common.module.scss'

export const SvgAccessDenied = (props: JSX.IntrinsicElements['svg']) => (
  <svg {...props} width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="60" cy="60" r="60" className={styles.fillPrimary100} />
    <ellipse cx="105" cy="81.75" rx="2.24989" ry="2.25" className={styles.fillPrimary300} />
    <path
      d="M91.7541 21.1176C92.0866 19.8836 93.9136 19.8837 94.2462 21.1176L94.4205 21.7643C94.5412 22.2122 94.9129 22.5578 95.3835 22.6595L95.7463 22.738C97.0849 23.0274 97.0849 24.8569 95.7463 25.1463L95.3835 25.2247C94.9129 25.3265 94.5412 25.6721 94.4205 26.12L94.2462 26.7667C93.9136 28.0006 92.0866 28.0006 91.7541 26.7667L91.5798 26.12C91.4591 25.6721 91.0873 25.3265 90.6167 25.2247L90.254 25.1463C88.9153 24.8569 88.9153 23.0274 90.254 22.738L90.6167 22.6595C91.0873 22.5578 91.4591 22.2122 91.5798 21.7643L91.7541 21.1176Z"
      className={styles.fillPrimary300}
    />
    <circle cx="66.75" cy="19.7307" r="1.5" className={styles.fillPrimary400} />
    <path
      d="M11.7532 55.4904C12.0192 54.5032 13.4808 54.5032 13.7468 55.4904L13.8862 56.0077C13.9828 56.3661 14.2802 56.6425 14.6566 56.7239L14.9468 56.7867C16.0177 57.0182 16.0177 58.4818 14.9468 58.7133L14.6566 58.7761C14.2802 58.8575 13.9828 59.1339 13.8862 59.4923L13.7468 60.0096C13.4808 60.9968 12.0192 60.9968 11.7532 60.0096L11.6138 59.4923C11.5172 59.1339 11.2198 58.8575 10.8434 58.7761L10.5532 58.7133C9.48228 58.4818 9.48228 57.0182 10.5532 56.7867L10.8434 56.7239C11.2198 56.6425 11.5172 56.3661 11.6138 56.0077L11.7532 55.4904Z"
      className={styles.fillPrimary400}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69.75 42V32.25C69.75 26.8652 65.3848 22.5 60 22.5C54.6152 22.5 50.25 26.8652 50.25 32.25V42H69.75ZM60 13.5C49.6447 13.5 41.25 21.8947 41.25 32.25V51H78.75V32.25C78.75 21.8947 70.3553 13.5 60 13.5Z"
      className={styles.fillPrimary400}
    />
    <rect width="9" height="3" transform="matrix(1 0 0 -1 41.25 40.5)" className={styles.fillPrimary600} />
    <rect width="9" height="3" transform="matrix(1 0 0 -1 69.75 40.5)" className={styles.fillPrimary600} />
    <g opacity="0.5" filter="url(#filter0_f_74036_1209)">
      <path
        d="M27.9271 50.5327C28.1711 47.4097 30.7764 45 33.9089 45H86.0911C89.2236 45 91.8289 47.4097 92.0729 50.5327L93.7254 71.6848C93.7418 71.8946 93.7429 72.1054 93.7289 72.3155C92.5537 89.8654 77.9754 103.5 60.3863 103.5H59.6137C42.0246 103.5 27.4463 89.8654 26.2711 72.3155C26.2571 72.1054 26.2582 71.8946 26.2746 71.6848L27.9271 50.5327Z"
        className={styles.fillPrimary500}
      />
    </g>
    <path
      d="M28.0079 45.7492C28.2057 42.7951 30.6596 40.5 33.6203 40.5H86.3797C89.3404 40.5 91.7943 42.7951 91.9921 45.7492L93.75 72C92.5629 89.7274 77.7671 103.5 60 103.5C42.2329 103.5 27.4371 89.7274 26.25 72L28.0079 45.7492Z"
      className={styles.fillWhite}
    />
    <g opacity="0.8" filter="url(#filter1_f_74036_1209)">
      <path
        d="M62.7501 73.584C64.6804 72.5868 66 70.5725 66 68.25C66 64.9363 63.3137 62.25 60 62.25C56.6863 62.25 54 64.9363 54 68.25C54 70.5725 55.3196 72.5868 57.2499 73.584L55.6944 80.8429C55.5943 81.3097 55.9503 81.75 56.4277 81.75H63.5723C64.0497 81.75 64.4057 81.3097 64.3056 80.8429L62.7501 73.584Z"
        strokeWidth="16"
        className={styles.strokePrimary300}
      />
    </g>
    <path
      d="M62.7501 73.584C64.6804 72.5868 66 70.5725 66 68.25C66 64.9363 63.3137 62.25 60 62.25C56.6863 62.25 54 64.9363 54 68.25C54 70.5725 55.3196 72.5868 57.2499 73.584L55.6944 80.8429C55.5943 81.3097 55.9503 81.75 56.4277 81.75H63.5723C64.0497 81.75 64.4057 81.3097 64.3056 80.8429L62.7501 73.584Z"
      className={styles.fillPrimary500}
    />
    <circle cx="100.5" cy="30.9807" r="1.5" className={styles.fillPrimary400} />
    <defs>
      <filter
        id="filter0_f_74036_1209"
        x="13.2614"
        y="32"
        width="93.4773"
        height="84.5"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="6.5" result="effect1_foregroundBlur_74036_1209" />
      </filter>
      <filter
        id="filter1_f_74036_1209"
        x="22"
        y="30.25"
        width="76"
        height="83.5"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="12" result="effect1_foregroundBlur_74036_1209" />
      </filter>
    </defs>
  </svg>
)
