import {
  WppActionButton,
  WppIconGear,
  WppIconSupportChat,
  WppIconWarning,
  WppTooltip,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { useTranslation, Trans } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { useProject } from 'hooks/useProject'
import { useProjectRole } from 'hooks/useProjectRole'
import styles from 'pages/components/appIssueCard/AppIssueCard.module.scss'
import { showProjectEditModal } from 'pages/components/projectModal/EditProjectModal'
import { AppIssueEnum } from 'types/projects/application'

const SupportIcon = () => {
  const { t } = useTranslation()
  return (
    <WppTooltip text={t('modals.app_issue_card.help_text')}>
      <WppIconSupportChat />
    </WppTooltip>
  )
}

const useAppIssueData = (issueType: AppIssueEnum, missingCtx: string[] = [], appVersionName?: string) => {
  const { t } = useTranslation()
  const { project } = useProject()
  const { isOwnerOrGlobalManage } = useProjectRole()

  const appName = appVersionName || t('modals.app_issue_card.App')
  const appNameLower = appVersionName || t('modals.app_issue_card.app')
  switch (issueType) {
    case AppIssueEnum.Deleted:
      return {
        title: t('modals.app_issue_card.deleted.title', { appName }),
        message: t('modals.app_issue_card.deleted.message'),
        actionIcon: <SupportIcon />,
      }
    case AppIssueEnum.NotPublished:
      return {
        title: t('modals.app_issue_card.not_published.title', { appName }),
        message: t('modals.app_issue_card.not_published.message'),
        actionIcon: <SupportIcon />,
      }
    case AppIssueEnum.NotAvailable:
      return {
        title: t('modals.app_issue_card.not_available.title', { appName }),
        message: t('modals.app_issue_card.not_available.message'),
        actionIcon: <SupportIcon />,
      }
    case AppIssueEnum.WrongContext:
      return {
        title: t('modals.app_issue_card.wrong_context.title', { appName: appNameLower }),
        message: (
          <Trans
            i18nKey="modals.app_issue_card.wrong_context.message"
            values={{
              context: missingCtx.map(level => `<bold>${level}</bold>`.toLowerCase()).join(` ${t('common.or')} `),
            }}
            components={{ bold: <WppTypography type="s-strong" /> }}
          />
        ),
        actionIcon: (
          <WppActionButton
            onClick={() => showProjectEditModal({ project, showBackButton: true })}
            disabled={!isOwnerOrGlobalManage}
          >
            <WppIconGear />
          </WppActionButton>
        ),
      }
    case AppIssueEnum.NoInstance:
      return {
        title: t('modals.app_issue_card.no_instance.title', { appName: appNameLower }),
        message: t('modals.app_issue_card.no_instance.message'),
        actionIcon: <></>,
      }
  }

  const _exhaustiveCheck: never = issueType
  throw new Error(_exhaustiveCheck)
}

interface Props {
  issueType: AppIssueEnum
  missingCtx?: string[]
  appName?: string
}

export const AppIssueCard = ({ issueType, missingCtx, appName }: Props) => {
  const { title, message, actionIcon } = useAppIssueData(issueType, missingCtx, appName)

  return (
    <Flex className={styles.card} align="start">
      <WppIconWarning className={styles.icon} />
      <Flex className={styles.body} direction="column">
        <WppTypography type="m-strong">{title}</WppTypography>
        <WppTypography type="s-body" className="wpp-spacing-4-top">
          {message}
        </WppTypography>
      </Flex>

      {actionIcon}
    </Flex>
  )
}
