import { WppIconApp, WppIconFolder } from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import { useMemo } from 'react'

import { useProjectTaskApi } from 'api/projects/queries/useProjectTaskApi'
import { useAssignMember } from 'hooks/useAssignMember'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { useProject } from 'hooks/useProject'
import { TaskDetailsModalView } from 'pages/project/components/tasks/components/taskDetailsModal/TaskDetailsModalView'
import { useHasProjectRole } from 'pages/project/hooks/useHasProjectRole'
import { createProjectModal } from 'pages/project/utils/createProjectModal'
import { ProjectRole, AppPermissions } from 'types/permissions/permissions'
import { TaskTargetType } from 'types/projects/tasks'
import { isEqualEmails } from 'utils/common'
import { NiceModalWrappedProps } from 'utils/createNiceModal'

export const taskTargetTypeMap = {
  [TaskTargetType.ACTIVITY]: {
    icon: <WppIconFolder />,
    name: 'project.tasks.item_type.activity',
  },
  [TaskTargetType.APPLICATION]: {
    icon: <WppIconApp />,
    name: 'project.tasks.item_type.application',
  },
  [TaskTargetType.PHASE]: {
    icon: <WppIconFolder />,
    name: 'project.tasks.item_type.phase',
  },
}

interface Props extends NiceModalWrappedProps {
  taskId: string
}

const TaskDetailsModal = ({ taskId, onClose, onCloseComplete, isOpen }: Props) => {
  const { data: task, isLoading: isTaskLoading, isError, error } = useProjectTaskApi({ params: { id: taskId } })

  const assignMember = useAssignMember(task?.assignUser)

  const {
    osContext: { userDetails },
  } = useOs()
  const { isInactive } = useProject()
  const { hasRole } = useHasProjectRole()
  const { isPermitted } = useIsPermitted()

  const isOwnerOrGlobalManage = hasRole([ProjectRole.OWNER]) || isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)

  const isMeAssignToThisPhase = useMemo(
    () => isEqualEmails(userDetails.email, task?.location?.phase?.assignUser),
    [userDetails.email, task?.location?.phase?.assignUser],
  )

  const isMeAssignToThisApp = useMemo(
    () => isEqualEmails(userDetails.email, assignMember?.email),
    [userDetails, assignMember],
  )

  const isMeAssignToThisActivity = useMemo(
    () => isEqualEmails(userDetails.email, task?.location?.activity?.assignUser),
    [task?.location?.activity?.assignUser, userDetails.email],
  )

  const canEdit =
    (isOwnerOrGlobalManage || isMeAssignToThisApp || isMeAssignToThisPhase || isMeAssignToThisActivity) && !isInactive

  return (
    <TaskDetailsModalView
      id="task-modal-view"
      task={task}
      canEdit={canEdit}
      isLoading={isTaskLoading}
      onClose={onClose}
      onCloseComplete={onCloseComplete}
      isOpen={isOpen}
      isError={isError}
      error={error}
    />
  )
}

export const { showModal: showTaskDetailsModal, useModal: useShowTaskDetailsModal } = createProjectModal<Props>(
  TaskDetailsModal,
  'task-details-modal',
)
