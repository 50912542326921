import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { useFetchWorkflowTemplateByIdApi } from 'api/templates/queries/useFetchWorkflowTemplateByIdApi'
import { Flex } from 'components/common/flex/Flex'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { TemplateView } from 'pages/components/templatePreviewModal/TemplateView'
import { ErrorState, TemplateViewSkeleton } from 'pages/components/templatePreviewModal/utils'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  templateId?: string
  btnText?: string

  handleSubmit(): void
}

const TemplatePreviewModal = ({ isOpen, handleSubmit, templateId, btnText, onClose, onCloseComplete }: Props) => {
  const { t } = useTranslation()

  const {
    data: template,
    isLoading: isTemplateLoading,
    isError,
    error,
  } = useFetchWorkflowTemplateByIdApi({
    params: { id: templateId! },
    enabled: !!templateId,
  })

  return (
    <SideModal
      open={isOpen}
      onWppSideModalClose={onClose}
      onWppSideModalCloseComplete={onCloseComplete}
      size="2xl"
      data-testid="view-template-modal"
    >
      {isTemplateLoading ? (
        <TemplateViewSkeleton />
      ) : (
        <>
          <WppTypography slot="header" type="2xl-heading" data-testid="view-template-modal-header">
            {!isError && template?.name}
          </WppTypography>
          {template && !isError ? <TemplateView template={template} /> : <ErrorState error={error} />}
          <Flex slot="actions" gap={12} justify="end">
            <WppButton variant="secondary" onClick={onClose}>
              {t('common.btn_cancel')}
            </WppButton>
            {!isError && (
              <WppButton
                variant="primary"
                size="m"
                onClick={() => {
                  handleSubmit()
                  onClose()
                }}
              >
                {btnText || t('templates.btn_use')}
              </WppButton>
            )}
          </Flex>
        </>
      )}
    </SideModal>
  )
}

export const { showModal: showTemplatePreview, useModal: useTemplatePreviewModal } = createNiceModal<Props>(
  TemplatePreviewModal,
  'template-preview-modal',
)
