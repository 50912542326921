import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useListNavigation,
  useInteractions,
  FloatingFocusManager,
  offset,
  flip,
  autoUpdate,
  FloatingPortal,
} from '@floating-ui/react'
import { WppDivider, WppIconSearch, WppInput, WppListItem, WppSpinner } from '@platform-ui-kit/components-library-react'
import { cloneElement, FC, ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import { ResponsibleUser } from 'pages/project/components/canvas/components/selectPerson/utils'
import styles from 'pages/project/components/canvas/components/selectPersonDropdown/SelectPersonDropdown.module.scss'

interface Props {
  options: ResponsibleUser[]
  avatarsCache: Record<string, string>
  children: ReactElement
  selectedId?: string
  isEmpty?: boolean
  isLoading?: boolean

  onSearchValueChange: (text: string) => void
  onSelect: (user: ResponsibleUser) => void
  onClose?: () => void
}
export const SelectPersonDropdown: FC<Props> = ({
  selectedId,
  options,
  onSearchValueChange,
  avatarsCache,
  children,
  onSelect,
  onClose,
  isEmpty,
  isLoading,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const { t } = useTranslation()
  const isInitialMount = useRef(false)

  useEffect(() => {
    if (!isOpen && isInitialMount.current) {
      onClose?.()
    }
  }, [isOpen, onClose])

  useEffect(() => {
    isInitialMount.current = true
  }, [])

  // setup Floating UI
  const { x, y, strategy, refs, reference, context } = useFloating({
    placement: 'bottom-start',
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [offset(5), flip({ padding: 10 })],
  })

  // setup Dropdown UI
  const listRef = useRef<Array<HTMLElement | null>>([])
  const click = useClick(context, { event: 'click' })
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'listbox' })
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
    loop: true,
  })
  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([click, dismiss, role, listNav])

  const handleSelect = (user: ResponsibleUser, avatarUrl: string) => {
    onSelect({ ...user, avatarUrl })
    setIsOpen(false)
  }

  return (
    <>
      {cloneElement(children, getReferenceProps({ ref: reference, ...children.props }))}
      <FloatingPortal>
        {isOpen && (
          <FloatingFocusManager context={context} modal={false}>
            <Flex
              direction="column"
              className={styles.floatingCard}
              ref={refs.setFloating}
              style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
                zIndex: 1000,
              }}
              {...getFloatingProps()}
            >
              <WppInput
                placeholder={t('common.search')!}
                onWppChange={event => onSearchValueChange(event.target.value)}
                data-testid="inline-search"
                className={styles.searchInput}
              >
                <WppIconSearch slot="icon-start" aria-label="Search icon" />
              </WppInput>

              <WppDivider className={styles.divider} />

              <Flex direction="column" gap={4} className={styles.scrollArea} data-testid="inline-dropdown-list">
                {isLoading && <WppSpinner className={styles.loading} color="var(--wpp-primary-color-300)" />}
                {isEmpty && (
                  <div className={styles.empty}>
                    <WppListItem className={styles.listItem} data-testid="inline-nothing-found">
                      <span slot="label">{t('common.search_no_results')}</span>
                    </WppListItem>
                  </div>
                )}
                {!isLoading &&
                  options.map((user, i) => (
                    <WppListItem
                      key={user.id}
                      className={styles.listItem}
                      ref={node => {
                        listRef.current[i] = node
                      }}
                      role="option"
                      tabIndex={i === activeIndex ? 0 : -1}
                      aria-selected={user.id === selectedId && i === activeIndex}
                      {...getItemProps({
                        onClick() {
                          handleSelect(user, user.avatarThumbnail ? avatarsCache[user.avatarThumbnail?.key] || '' : '')
                        },
                        onKeyDown(event) {
                          if (event.key === 'Enter') {
                            event.preventDefault()
                            handleSelect(
                              user,
                              user.avatarThumbnail ? avatarsCache[user.avatarThumbnail?.key] || '' : '',
                            )
                          }

                          if (event.key === ' ' && !context.dataRef.current.typing) {
                            event.preventDefault()
                            handleSelect(
                              user,
                              user.avatarThumbnail ? avatarsCache[user.avatarThumbnail?.key] || '' : '',
                            )
                          }
                        },
                      })}
                      checked={user.id === selectedId || i === activeIndex}
                      data-testid="inline-list-item"
                    >
                      <span slot="label">{user.label}</span>
                      <span slot="caption">{user.email}</span>
                      <Avatar
                        slot="left"
                        size="xs"
                        name={user.label}
                        src={
                          user?.avatarUrl ||
                          (user.avatarThumbnail?.key ? avatarsCache[user.avatarThumbnail?.key as string] || '' : '')
                        }
                      />
                    </WppListItem>
                  ))}
              </Flex>
            </Flex>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    </>
  )
}
