import { WppListItem } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import { useAssignMember } from 'hooks/useAssignMember'
import { ApplicationLogo } from 'pages/components/applicationLogo/ApplicationLogo'
import { Calendar } from 'pages/project/components/canvas/components/phase/Calendar'
import styles from 'pages/project/components/canvas/components/phase/detailsModal/PhaseDetailsModal.module.scss'
import { ResponsiblePerson } from 'pages/project/components/canvas/components/phase/ResponsiblePerson'
import { StatusText } from 'pages/project/components/tasks/components/statusText/StatusText'
import { TaskStatus } from 'types/projects/tasks'
import { ApplicationItem, PhaseItem, PhaseItemType } from 'types/projects/workflow'

interface Props {
  app: PhaseItem<ApplicationItem>
  setSelectedItem: (appId: string | null, type: PhaseItemType) => void
}

export const PhaseDetailsModalApp = ({ app, setSelectedItem }: Props) => {
  const assignMember = useAssignMember(app.item.assignUser)

  const showTask = app.item.task?.status === TaskStatus.COMPLETED
  const showCalendar = app.item.startDate || app.item.endDate

  const subBur = showTask ? (
    <div className={styles.dateWrapper}>
      <StatusText statusKey={app.item.task!.status} data-testid="preview-activity-task-status" small />
    </div>
  ) : showCalendar ? (
    <div className={styles.dateWrapper}>
      <Calendar startDate={app.item.startDate} endDate={app.item.endDate} hideIcon size="xs-body" />
    </div>
  ) : null

  return (
    <WppListItem
      onWppChangeListItem={() => setSelectedItem(app.item.id, PhaseItemType.Application)}
      className={styles.appItem}
      data-testid="phase-details-app-item"
    >
      <Flex
        inline
        direction="column"
        className={styles.logoWorkflow}
        justify="center"
        align="center"
        data-testid="preview-application-logo"
        slot="left"
      >
        <ApplicationLogo logo={app?.item.logoUrl} />
      </Flex>
      <span slot="label" data-testid="phase-details-app-name">
        {app.item.name}
      </span>

      <span slot="caption">{subBur}</span>

      <div slot="right">
        <ResponsiblePerson assignMember={assignMember} size="xs" data-testid="task-item-assignee" />
      </div>
    </WppListItem>
  )
}
