import clsx from 'clsx'
import { memo, useMemo } from 'react'
import { Handle, Position, useReactFlow, useStore, ReactFlowState, Edge } from 'reactflow'

import { CanvasApplication } from 'pages/project/components/canvas/components/fluidCanvas/components/canvasApplication/CanvasApplication'
import styles from 'pages/project/components/canvas/components/fluidCanvas/components/fluidNodes/AppNode.module.scss'
import {
  EdgePosition,
  ConnectionType,
  HIDE_DATA_CONNECTION,
  splitByUnderscore,
} from 'pages/project/components/canvas/components/fluidCanvas/utils'
import { AgencyDTOBrief } from 'types/agencies/agency'
import { ApplicationItem } from 'types/projects/workflow'

interface Props {
  data: NodeData
  id: string
  selected: boolean
  preview?: boolean
  isConnectable?: boolean
}
interface NodeData {
  label: string
  item: ApplicationItem
  agency: AgencyDTOBrief
  preview?: boolean
  editMode?: boolean
  templateView?: boolean
  isInactive?: boolean
  parentActivityId?: string
}

const connectionNodeIdSelector = ({ connectionNodeId }: ReactFlowState): string | null => connectionNodeId
const connectionNodeType = ({ connectionHandleId }: ReactFlowState) => connectionHandleId

export const AppNode = memo(({ data, id, selected, isConnectable }: Props) => {
  const parentNode = useStore(store => {
    const node = store.nodeInternals.get(id)

    return node?.parentNode
  })

  const { getEdges } = useReactFlow()
  const connectionNodeId = useStore(connectionNodeIdSelector)
  const connectionHandleId = useStore(connectionNodeType)

  const isHandleConnection = useMemo(() => {
    return connectionHandleId?.includes(ConnectionType.FLOW)
  }, [connectionHandleId])

  const isHandleData = useMemo(() => {
    return connectionHandleId?.includes(ConnectionType.DATA)
  }, [connectionHandleId])

  const isTarget = useMemo(() => {
    if (!connectionHandleId) return

    return connectionNodeId
  }, [connectionNodeId, connectionHandleId])

  const nodeTargetConnectionHandlers = useMemo(() => {
    if (!connectionHandleId) return {}

    const edges = getEdges()
    const connectedEdges = edges.filter((edge: Edge) => {
      return (
        (edge.source === connectionNodeId || connectionNodeId === edge.target) &&
        edge.target === id &&
        edge.targetHandle?.includes(ConnectionType.FLOW)
      )
    })

    return connectedEdges.reduce<{ [key in EdgePosition]?: true }>((acc, edge) => {
      const targetAnchor = splitByUnderscore(edge.targetHandle!)[0] as EdgePosition
      acc[targetAnchor] = true
      return acc
    }, {})
  }, [connectionHandleId, connectionNodeId, getEdges, id])

  const nodeTargetDataHandlers = useMemo(() => {
    if (!connectionHandleId) return {}

    const edges = getEdges()
    const connectedEdges = edges.filter((edge: Edge) => {
      return (
        (edge.source === connectionNodeId || connectionNodeId === edge.target) &&
        edge.target === id &&
        edge.targetHandle?.includes(ConnectionType.DATA)
      )
    })

    return connectedEdges.reduce<{ [key in EdgePosition]?: true }>((acc, edge) => {
      const targetAnchor = splitByUnderscore(edge.targetHandle!)[0] as EdgePosition
      acc[targetAnchor] = true
      return acc
    }, {})
  }, [connectionHandleId, connectionNodeId, getEdges, id])

  const hiddenTargetConnectionHandle = !isTarget || connectionNodeId === id || isHandleData || !isConnectable
  const hiddenTargetDataHandle = !isTarget || connectionNodeId === id || isHandleConnection || !isConnectable

  const hiddenSourceConnectionHandle =
    isTarget || !selected || connectionNodeId === id || isHandleData || data.preview || !isConnectable

  const hiddenSourceDataHandle =
    isTarget || !selected || connectionNodeId === id || isHandleConnection || data.preview || !isConnectable

  const isConnectableHandleProps = useMemo(() => {
    return {
      isConnectable,
      isConnectableStart: isConnectable,
      isConnectableEnd: isConnectable,
    }
  }, [isConnectable])

  return (
    <div
      className={clsx({
        [styles.nodeCard]: true,
        [styles.disabledState]: connectionNodeId && !isTarget && isHandleData,
      })}
      data-testid={`app-fluid-node-${id}`}
      data-parentid={parentNode}
    >
      {!parentNode && (
        <Handle
          type="target"
          position={Position.Top}
          id={`${EdgePosition.TOP}_${ConnectionType.FLOW}`}
          {...isConnectableHandleProps}
          className={clsx({
            [styles.connectionHandle]: true,
            [styles.topHandle]: true,
            [styles.hiddenHandle]: hiddenTargetConnectionHandle || nodeTargetConnectionHandlers[EdgePosition.TOP],
          })}
          data-testid="top-target-anchor"
        />
      )}
      {!HIDE_DATA_CONNECTION && !parentNode && (
        <Handle
          type="target"
          position={Position.Top}
          {...isConnectableHandleProps}
          id={`${EdgePosition.TOP}_${ConnectionType.DATA}`}
          className={clsx({
            [styles.dataHandle]: true,
            [styles.topDataHandle]: true,
            [styles.hiddenHandle]: hiddenTargetDataHandle || nodeTargetDataHandlers[EdgePosition.TOP],
          })}
        />
      )}
      {!parentNode && (
        <Handle
          type="target"
          position={Position.Right}
          {...isConnectableHandleProps}
          id={`${EdgePosition.RIGHT}_${ConnectionType.FLOW}`}
          className={clsx({
            [styles.connectionHandle]: true,
            [styles.rightHandle]: true,
            [styles.hiddenHandle]: hiddenTargetConnectionHandle || nodeTargetConnectionHandlers[EdgePosition.RIGHT],
          })}
          data-testid="right-target-anchor"
        />
      )}
      {!HIDE_DATA_CONNECTION && (
        <Handle
          type="target"
          position={Position.Right}
          {...isConnectableHandleProps}
          id={`${EdgePosition.RIGHT}_${ConnectionType.DATA}`}
          className={clsx({
            [styles.dataHandle]: true,
            [styles.rightDataHandle]: true,
            [styles.hiddenHandle]: hiddenTargetDataHandle || nodeTargetDataHandlers[EdgePosition.RIGHT],
          })}
        />
      )}
      {!parentNode && (
        <Handle
          type="target"
          position={Position.Bottom}
          {...isConnectableHandleProps}
          id={`${EdgePosition.BOTTOM}_${ConnectionType.FLOW}`}
          className={clsx({
            [styles.connectionHandle]: true,
            [styles.bottomHandle]: true,
            [styles.hiddenHandle]: hiddenTargetConnectionHandle || nodeTargetConnectionHandlers[EdgePosition.BOTTOM],
          })}
          data-testid="bottom-target-anchor"
        />
      )}
      {!HIDE_DATA_CONNECTION && !parentNode && (
        <Handle
          type="target"
          position={Position.Bottom}
          {...isConnectableHandleProps}
          id={`${EdgePosition.BOTTOM}_${ConnectionType.DATA}`}
          className={clsx({
            [styles.dataHandle]: true,
            [styles.bottomDataHandle]: true,
            [styles.hiddenHandle]: hiddenTargetDataHandle || nodeTargetDataHandlers[EdgePosition.BOTTOM],
          })}
        />
      )}
      {!parentNode && (
        <Handle
          type="target"
          position={Position.Left}
          {...isConnectableHandleProps}
          id={`${EdgePosition.LEFT}_${ConnectionType.FLOW}`}
          className={clsx({
            [styles.connectionHandle]: true,
            [styles.leftHandle]: true,
            [styles.hiddenHandle]: hiddenTargetConnectionHandle || nodeTargetConnectionHandlers[EdgePosition.LEFT],
          })}
          data-testid="left-target-anchor"
        />
      )}
      {!HIDE_DATA_CONNECTION && (
        <Handle
          type="target"
          position={Position.Left}
          {...isConnectableHandleProps}
          id={`${EdgePosition.LEFT}_${ConnectionType.DATA}`}
          className={clsx({
            [styles.dataHandle]: true,
            [styles.leftDataHandle]: true,
            [styles.hiddenHandle]: hiddenTargetDataHandle || nodeTargetDataHandlers[EdgePosition.LEFT],
          })}
        />
      )}
      <CanvasApplication
        application={data.item}
        agency={data.agency}
        isEditable={data.editMode}
        preview={data.preview}
        templateView={data.templateView}
        isInactive={data.isInactive}
        activityId={data.parentActivityId}
      />
      {!parentNode && (
        <Handle
          type="source"
          position={Position.Top}
          {...isConnectableHandleProps}
          id={`${EdgePosition.TOP}_${ConnectionType.FLOW}`}
          className={clsx({
            [styles.connectionHandle]: true,
            [styles.topHandle]: true,
            [styles.hiddenHandle]: hiddenSourceConnectionHandle,
            [styles.selectedConnectionHandle]: selected,
          })}
          data-testid="top-source-anchor"
        />
      )}
      {!HIDE_DATA_CONNECTION && !parentNode && (
        <Handle
          type="source"
          position={Position.Top}
          {...isConnectableHandleProps}
          id={`${EdgePosition.TOP}_${ConnectionType.DATA}`}
          className={clsx({
            [styles.dataHandle]: true,
            [styles.topDataHandle]: true,
            [styles.hiddenHandle]: hiddenSourceDataHandle,
            [styles.selectedDataHandle]: selected,
          })}
        />
      )}
      {!parentNode && (
        <Handle
          type="source"
          position={Position.Right}
          {...isConnectableHandleProps}
          id={`${EdgePosition.RIGHT}_${ConnectionType.FLOW}`}
          className={clsx({
            [styles.connectionHandle]: true,
            [styles.rightHandle]: true,
            [styles.hiddenHandle]: hiddenSourceConnectionHandle,
            [styles.selectedConnectionHandle]: selected,
          })}
          data-testid="right-source-anchor"
        />
      )}
      {!HIDE_DATA_CONNECTION && (
        <Handle
          type="source"
          position={Position.Right}
          {...isConnectableHandleProps}
          id={`${EdgePosition.RIGHT}_${ConnectionType.DATA}`}
          className={clsx({
            [styles.dataHandle]: true,
            [styles.rightDataHandle]: true,
            [styles.hiddenHandle]: hiddenSourceDataHandle,
            [styles.selectedDataHandle]: selected,
          })}
        />
      )}
      {!parentNode && (
        <Handle
          type="source"
          position={Position.Bottom}
          {...isConnectableHandleProps}
          id={`${EdgePosition.BOTTOM}_${ConnectionType.FLOW}`}
          className={clsx({
            [styles.connectionHandle]: true,
            [styles.bottomHandle]: true,
            [styles.hiddenHandle]: hiddenSourceConnectionHandle,
            [styles.selectedConnectionHandle]: selected,
          })}
          data-testid="bottom-source-anchor"
        />
      )}
      {!HIDE_DATA_CONNECTION && !parentNode && (
        <Handle
          type="source"
          position={Position.Bottom}
          {...isConnectableHandleProps}
          id={`${EdgePosition.BOTTOM}_${ConnectionType.DATA}`}
          className={clsx({
            [styles.dataHandle]: true,
            [styles.bottomDataHandle]: true,
            [styles.hiddenHandle]: hiddenSourceDataHandle,
            [styles.selectedDataHandle]: selected,
          })}
        />
      )}
      {!parentNode && (
        <Handle
          type="source"
          position={Position.Left}
          {...isConnectableHandleProps}
          id={`${EdgePosition.LEFT}_${ConnectionType.FLOW}`}
          className={clsx({
            [styles.connectionHandle]: true,
            [styles.leftHandle]: true,
            [styles.hiddenHandle]: hiddenSourceConnectionHandle,
            [styles.selectedConnectionHandle]: selected,
          })}
          data-testid="left-source-anchor"
        />
      )}
      {!HIDE_DATA_CONNECTION && (
        <Handle
          type="source"
          position={Position.Left}
          {...isConnectableHandleProps}
          id={`${EdgePosition.LEFT}_${ConnectionType.DATA}`}
          className={clsx({
            [styles.dataHandle]: true,
            [styles.leftDataHandle]: true,
            [styles.hiddenHandle]: hiddenSourceDataHandle,
            [styles.selectedDataHandle]: selected,
          })}
        />
      )}
    </div>
  )
})
