import { WppButton, WppIconPlus, WppSkeleton, WppSpinner } from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import { FC, RefCallback, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useProjectInfiniteApi } from 'api/projects/queries/useProjectInfiniteApi'
import { EmptyState } from 'components/common/emptyState/EmptyState'
import { Flex } from 'components/common/flex/Flex'
import { useInfiniteFetchNextPage } from 'hooks/useInfiniteFetchNextPage'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { showCreateProjectModal } from 'pages/components/projectModal/CreateProjectModal'
import { ProjectListItem } from 'pages/dashboard/components/projectsCardView/components/projectItem/ProjectListItem'
import styles from 'pages/dashboard/components/projectsCardView/ProjectCardView.module.scss'
import { useIsFiltersActive } from 'pages/dashboard/utils/useIsFiltersActive'
import { useRemainingPaginationItems } from 'pages/project/hooks/useRemainingPaginationItems'
import { AppPermissions } from 'types/permissions/permissions'
import { ProjectFilter, ProjectOwnership } from 'types/projects/projects'

interface Props {
  filter: ProjectFilter
  setProjectsExist: (exist: boolean) => void
}

export const ProjectCardView: FC<Props> = ({ filter, setProjectsExist }) => {
  const { search, ownership, type, workspace, status } = filter
  const { t } = useTranslation()
  const {
    osContext: {
      userDetails: { dateLocale },
    },
  } = useOs()

  const [loadMoreRef, setLoadMoreRef] = useState<HTMLDivElement>(null!)
  const setRef: RefCallback<HTMLDivElement> = useCallback(node => setLoadMoreRef(node!), [])

  const isFilterActive = useIsFiltersActive(filter)

  /*
   * @TODO: this should be removed, because now BE calculate empty array like
   *   empty filter and return all items, so when `workspace: []` they shouldn't return anything.
   *   JIRA: WPPLONOP-10970
   * */
  const enabled = !workspace || (Array.isArray(workspace) && !!workspace.length)
  const { isPermitted } = useIsPermitted()
  const canCreateProject =
    isPermitted(AppPermissions.ORCHESTRATION_PROJECTS_CREATE) || isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)

  const {
    data: projects,
    response,
    isLoading: isLoadingProjects,
    isRefetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useProjectInfiniteApi({
    enabled,
    params: {
      search,
      ownership: ownership === ProjectOwnership.ALL ? undefined : ownership,
      type,
      workspace,
      status,
    },
  })

  const paginator = response?.pages?.[response.pages.length - 1]?.data?.paginator

  useEffect(() => {
    setProjectsExist(!!projects?.length)
  }, [projects, setProjectsExist])

  const { itemsRemaining } = useRemainingPaginationItems({
    itemsPerPage: paginator?.itemsPerPage,
    page: paginator?.page,
    totalItems: paginator?.totalItems,
  })

  const isFetching = isRefetching || isFetchingNextPage

  useInfiniteFetchNextPage({
    loadMoreRef,
    isFetchingNextPage: isFetching,
    fetchNextPage,
    hasNextPage,
  })

  if (isLoadingProjects || isRefetching) {
    if (isFilterActive) {
      return (
        <Flex justify="center" align="center" className={styles.searchingSpinner}>
          {isLoadingProjects && <WppSpinner size="l" />}
        </Flex>
      )
    }

    return (
      <div className={styles.container}>
        {Array.from({ length: 6 }).map((_, index) => (
          <WppSkeleton key={index} variant="rectangle" height="166px" />
        ))}
      </div>
    )
  }

  if (!projects.length) {
    const message = isFilterActive ? t('common.no_search_results') : t('project.list.empty_state_no_projects')

    return (
      <EmptyState
        title={message}
        filtersApplied={isFilterActive}
        description={
          isFilterActive ? t('common.no_results_description') : t('project.list.empty_state_no_projects_description')
        }
        testToken="projects"
      >
        {!isFilterActive && canCreateProject && (
          <WppButton size="m" onClick={() => showCreateProjectModal()} data-testid="create-new-project">
            <WppIconPlus slot="icon-start" /> {t('dashboard.btn_add_project')}
          </WppButton>
        )}
      </EmptyState>
    )
  }

  return (
    <>
      <div className={styles.container} data-testid="project-card-list">
        <>
          {(enabled ? projects : []).map(project => (
            <ProjectListItem key={project.id} project={project} dateLocale={dateLocale} />
          ))}
          {isFetchingNextPage && (
            <>
              {Array.from({ length: itemsRemaining }).map((_, index) => (
                <WppSkeleton key={index} variant="rectangle" height="166px" />
              ))}
            </>
          )}
        </>
      </div>
      <Flex justify="center" ref={setRef} className={styles.spinner}>
        {isLoadingProjects && <WppSpinner size="l" />}
      </Flex>
    </>
  )
}
