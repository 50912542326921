import { WppSpinner } from '@platform-ui-kit/components-library-react'
import { useSearchParams } from 'react-router-dom'

import { useAccessTokenApi } from 'api/wrike/queries/useAccessTokenApi'
import { WrikeAuthPostEvent } from 'auth/wrike/types'
import { useWrikeRedirectUrl } from 'auth/wrike/utils'
import { Flex } from 'components/common/flex/Flex'

const postMessage = (message: WrikeAuthPostEvent) => {
  window.opener.postMessage(message, window.location.origin)
  window.close()
}

export function WrikeAuth() {
  const redirectUri = useWrikeRedirectUrl()
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries(searchParams.entries()) as { code: string; state: string }

  const { data: auth } = useAccessTokenApi({
    enabled: !!params.code && !!params.state,
    params: {
      ...params,
      redirectUri,
    },
  })

  if (!params.code || !params.state) {
    postMessage({ auth, error: true, wrike: true })
  }

  if (auth?.accessToken) {
    postMessage({ auth, error: false, wrike: true })
  }

  return (
    <Flex align="center" justify="center" style={{ height: '100%' }}>
      <WppSpinner size="l" />
    </Flex>
  )
}
