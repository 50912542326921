import { useMemo } from 'react'

import { Flex } from 'components/common/flex/Flex'
import { PhaseDetailsModalActivity } from 'pages/project/components/canvas/components/phase/detailsModal/PhaseDetailsModalActivity'
import { ActivityItem, PhaseItem, PhaseItemType } from 'types/projects/workflow'

interface Props {
  activities: PhaseItem<ActivityItem>[]
  setSelectedItem: (appId: string | null, type: PhaseItemType) => void
}

export const PhaseDetailsActivities = ({ activities, setSelectedItem }: Props) => {
  const sortedActivities = useMemo(() => activities.sort((a, b) => a.orderNumber - b.orderNumber), [activities])

  return (
    <Flex direction="column" gap={8}>
      {sortedActivities.map(activity => (
        <PhaseDetailsModalActivity activity={activity} key={activity.id} setSelectedItem={setSelectedItem} />
      ))}
    </Flex>
  )
}
