import {
  WppTypography,
  WppButton,
  WppActionButton,
  WppIconReset,
  WppCheckbox,
} from '@platform-ui-kit/components-library-react'
import { useMemo } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { FormSelect } from 'components/form/formSelect/FormSelect'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { getAppliedFilters } from 'pages/dashboard/components/utils'
import styles from 'pages/project/components/files/Files.module.scss'
import { initialTasksFilters } from 'pages/project/components/tasks/Tasks'
import { datesRangeMapping } from 'pages/project/components/tasks/utils'
import { TasksFilter } from 'types/projects/tasks'
import { NiceModalWrappedProps, createNiceModal } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  filters?: TasksFilter
  onFiltersSave: (filters: TasksFilter) => void
}

export const TasksFiltersModal = ({ isOpen, onClose, onCloseComplete, filters, onFiltersSave }: Props) => {
  const form = useForm({ defaultValues: filters })
  const { t } = useTranslation()

  const {
    handleSubmit,
    formState: { isSubmitting },
    watch,
    reset,
    setValue,
    getValues,
  } = form

  const onSubmit = handleSubmit(value => {
    onFiltersSave(value)
    onClose()
  })

  const dueDatesOptions = useMemo(() => {
    return Object.entries(datesRangeMapping).map(([key, value]) => ({
      value: key,
      label: t(value),
    }))
  }, [t])

  const onReset = () => {
    reset(initialTasksFilters)
  }

  const watchAllFields = watch(['archived', 'dueDateRanges'])

  const filtersCounts = useMemo(
    () => getAppliedFilters(watchAllFields),

    [watchAllFields],
  )

  return (
    <FormProvider {...form}>
      <SideModal
        size="m"
        open={isOpen}
        formConfig={{ onSubmit }}
        onWppSideModalClose={onClose}
        onWppSideModalCloseComplete={onCloseComplete}
        data-testid="tasks-filter-modal"
      >
        <WppTypography slot="header" type="2xl-heading">
          {t('project.tasks.filters.title')}
        </WppTypography>
        <div slot="body">
          <Flex direction="column" className={styles.filtersWrapper} gap={16}>
            <FormSelect
              name="dueDateRanges"
              type="multiple"
              data-testid="due-date-select"
              options={dueDatesOptions}
              labelConfig={{ text: t('project.tasks.filters.field_type_label') }}
              placeholder={t('project.tasks.filters.field_type_placeholder')!}
              required
              withFolder
              withSearch
            />
            <WppCheckbox
              checked={getValues('archived')}
              labelConfig={{ text: t('project.tasks.filters.archived_checkbox') }}
              required
              onWppChange={({ detail: { checked } }) => setValue('archived', checked)}
              data-testid="archive-tasks-checkbox"
              name="archived"
            />
          </Flex>
        </div>
        <Flex justify="between" slot="actions">
          <Flex>
            {filtersCounts > 0 && (
              <WppActionButton variant="primary" onClick={onReset}>
                <WppIconReset className={styles.resetIcon} />
                {t('common.btn_reset')}
              </WppActionButton>
            )}
          </Flex>
          <Flex gap={12}>
            <WppButton size="m" variant="secondary" onClick={onClose}>
              {t('common.btn_cancel')}
            </WppButton>
            <WppButton size="m" type="submit" variant="primary" loading={isSubmitting}>
              {t('common.btn_apply')}
            </WppButton>
          </Flex>
        </Flex>
      </SideModal>
    </FormProvider>
  )
}

export const { showModal: showTasksFiltersModal } = createNiceModal<Props>(TasksFiltersModal, 'tasks-filters-modal')
