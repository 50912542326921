import {
  WppActionButton,
  WppButton,
  WppCard,
  WppIconEdit,
  WppIconMore,
  WppIconPlus,
  WppIconTrash,
  WppListItem,
  WppMenuContext,
  WppPill,
  WppTag,
  WppTypography,
  WppTooltip,
} from '@platform-ui-kit/components-library-react'
import { SelectionChangedEvent } from 'ag-grid-community/dist/lib/events'
import { AgGridReact } from 'ag-grid-react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import { ColDef, Table } from 'components/common/table'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { useProject } from 'hooks/useProject'
import { showInviteMembersModal } from 'pages/project/components/members/components/inviteMembersModal/InviteMembersModal'
import { showMemberModal } from 'pages/project/components/members/components/memberModal/MemberModal'
import { showRemoveMembersModal } from 'pages/project/components/members/components/removeMembersModal/RemoveMembersModal'
import styles from 'pages/project/components/members/Members.module.scss'
import { useHasProjectRole } from 'pages/project/hooks/useHasProjectRole'
import { Members } from 'types/members/members'
import { AppPermissions, ProjectRole } from 'types/permissions/permissions'
import { ProjectStatus } from 'types/projects/projects'
import { capitalizeFirstLetter, join } from 'utils/common'

export const MembersTab = () => {
  const gridRef = useRef<AgGridReact<Members>>(null)
  const { t } = useTranslation()

  const { project, members, isMembersLoading, isUserConnectWrike } = useProject()
  const [selectedMembers, setSelectedMembers] = useState<Members[]>([])
  const { isResponsible } = useHasProjectRole()

  const { isPermitted } = useIsPermitted()
  const { hasRole } = useHasProjectRole()
  const isOwnerOrGlobalManage = hasRole([ProjectRole.OWNER]) || isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)

  const ownersSelected = useMemo(
    () => selectedMembers.filter(member => member.role === ProjectRole.OWNER),
    [selectedMembers],
  )
  const allOwners = useMemo(() => members.filter(member => member.role === ProjectRole.OWNER), [members])

  const isSelectedResponsible = useMemo(
    () => selectedMembers.some(member => isResponsible(member.email)),
    [isResponsible, selectedMembers],
  )

  const withWrike = isUserConnectWrike === null ? false : !isUserConnectWrike
  const isProjectDone = [ProjectStatus.COMPLETED, ProjectStatus.ARCHIVED].includes(project.status)

  useEffect(() => {
    if (gridRef.current) setSelectedMembers(gridRef.current?.api?.getSelectedRows() || [])
  }, [members])

  const openMemberModal = useCallback(
    ({ member: selectedMember, allOwnersSelected }: { member: Members; allOwnersSelected: boolean }) => {
      showMemberModal({ member: selectedMember, project, allOwnersSelected })
    },
    [project],
  )

  const openDeleteMemberModal = useCallback(
    (member: Members[]) => {
      showRemoveMembersModal({
        membersToDelete: member,
        projectId: project.id,
      })
    },
    [project.id],
  )

  const getTooltip = useCallback(
    (isMultipleSelected: boolean) => {
      if (withWrike || (isProjectDone && (!isMultipleSelected || isSelectedResponsible))) {
        return t('project.members.cant_remove')!
      }

      if (allOwners.length === 1) {
        return t('project.members.cant_remove_owner')!
      }

      return t('project.members.cant_remove_all_owners')!
    },
    [allOwners.length, isProjectDone, isSelectedResponsible, t, withWrike],
  )

  const columnDefs = useMemo<ColDef<Members>[]>(
    () => [
      {
        colId: 'name',
        flex: 2,
        headerCheckboxSelection: isOwnerOrGlobalManage && !withWrike,
        checkboxSelection: isOwnerOrGlobalManage && !withWrike,
        headerName: 'Name',
        cellRenderer: ({ data }) => {
          const { firstname, lastname, email, avatarUrl } = data!
          const userName = join([firstname, lastname], ' ')

          return (
            <Flex gap={16} align="center">
              <Avatar size="s" name={userName || email} src={avatarUrl ?? ''} />
              <Flex direction="column" className={styles.overflow}>
                <WppTypography type="s-body" data-testid="table-username">
                  {userName}
                </WppTypography>
                <WppTypography type="xs-body" data-testid="table-email">
                  {email}
                </WppTypography>
              </Flex>
            </Flex>
          )
        },
      },
      {
        colId: 'agency',
        flex: 1,
        headerName: 'Organization',
        cellRenderer: ({ data }) => <WppTypography type="s-body">{data?.agency}</WppTypography>,
      },
      {
        colId: 'jobTitle',
        flex: 1,
        headerName: 'Job title',
        cellRenderer: ({ data }) => <WppTypography type="s-body">{data?.jobTitle || '-'}</WppTypography>,
      },
      {
        colId: 'access',
        headerName: 'Access',
        flex: 1,
        cellRenderer: ({ data }) => {
          return (
            <WppTag
              label={capitalizeFirstLetter(data?.role?.toLowerCase())}
              className={styles[data?.role || ProjectRole.VIEWER]}
              variant="neutral"
            />
          )
        },
      },
      isOwnerOrGlobalManage
        ? {
            width: 60,
            colId: 'actions',
            cellRenderer: ({ data }) => (
              <WppMenuContext
                className={styles.rowContextMenu}
                dropdownConfig={{
                  appendTo: () => document.body,
                  placement: 'bottom-end',
                }}
              >
                <WppActionButton slot="trigger-element" variant="secondary">
                  <WppIconMore direction="horizontal" slot="icon-start" />
                </WppActionButton>
                <Flex direction="column" gap={4}>
                  <WppListItem
                    onWppChangeListItem={() =>
                      openMemberModal({
                        member: data!,
                        allOwnersSelected: allOwners.length === 1 && data?.role === ProjectRole.OWNER,
                      })
                    }
                    data-testid="edit-group-action"
                  >
                    <WppIconEdit slot="left" />
                    <WppTypography slot="label" type="s-body">
                      {t('common.btn_edit')}
                    </WppTypography>
                  </WppListItem>
                  {withWrike ||
                  (allOwners.length === 1 && data?.role === ProjectRole.OWNER) ||
                  (isProjectDone && isResponsible(data?.email)) ? (
                    <WppTooltip config={{ placement: 'left' }} text={getTooltip(false)}>
                      <WppListItem data-testid="delete-group-context-action" disabled={true}>
                        <WppIconTrash slot="left" />
                        <span slot="label">{t('common.btn_remove')}</span>
                      </WppListItem>
                    </WppTooltip>
                  ) : (
                    <WppListItem
                      onWppChangeListItem={() => openDeleteMemberModal([data!])}
                      data-testid="delete-group-context-action"
                    >
                      <WppIconTrash slot="left" />
                      <WppTypography slot="label" type="s-body">
                        {t('common.btn_remove')}
                      </WppTypography>
                    </WppListItem>
                  )}
                </Flex>
              </WppMenuContext>
            ),
            maxWidth: 60,
          }
        : {},
    ],
    [
      isOwnerOrGlobalManage,
      withWrike,
      t,
      allOwners.length,
      isProjectDone,
      isResponsible,
      getTooltip,
      openMemberModal,
      openDeleteMemberModal,
    ],
  )

  const handleSelectionChanged = ({ api }: SelectionChangedEvent) => setSelectedMembers(api.getSelectedRows())

  return (
    <>
      <WppCard className={styles.container}>
        <Flex direction="column" gap={29} style={{ height: '100%', width: '100%' }}>
          <Flex justify="end">
            {isOwnerOrGlobalManage && !withWrike && (
              <Flex gap={20}>
                {!!selectedMembers.length ? (
                  <>
                    <WppPill
                      removable={true}
                      type="display"
                      label={`${selectedMembers.length} selected`}
                      name="chip"
                      onWppClose={() => gridRef.current?.api.deselectAll()}
                    />

                    {ownersSelected.length === allOwners.length || (isSelectedResponsible && isProjectDone) ? (
                      <WppTooltip text={getTooltip(true)}>
                        <WppActionButton variant="destructive" data-testid="delete-group-card-action" disabled={true}>
                          <WppIconTrash slot="icon-start" />
                          {t('common.btn_remove')}
                        </WppActionButton>
                      </WppTooltip>
                    ) : (
                      <WppActionButton
                        variant="destructive"
                        data-testid="delete-group-card-action"
                        onClick={() => openDeleteMemberModal(selectedMembers)}
                      >
                        <WppIconTrash slot="icon-start" />
                        {t('common.btn_remove')}
                      </WppActionButton>
                    )}
                  </>
                ) : (
                  <WppButton
                    size="s"
                    onClick={() => showInviteMembersModal({ actualMembers: members })}
                    disabled={isMembersLoading}
                  >
                    <WppIconPlus slot="icon-start" />
                    {t('project.members.invite_members')}
                  </WppButton>
                )}
              </Flex>
            )}
          </Flex>
          <Table
            ref={gridRef}
            rowData={members}
            cacheBlockSize={50}
            rowHeight={60}
            columnDefs={columnDefs}
            rowSelection="multiple"
            suppressPaginationPanel
            suppressRowClickSelection
            onSelectionChanged={handleSelectionChanged}
          />
        </Flex>
      </WppCard>
    </>
  )
}
