import { memo } from 'react'

import { useOpenApplication } from 'hooks/application/useOpenApplication'
import { useAssignMember } from 'hooks/useAssignMember'
import { CanvasEditableApplication } from 'pages/project/components/canvas/components/fluidCanvas/components/canvasApplication/CanvasEditableApplication'
import { CanvasPreviewApplication } from 'pages/project/components/canvas/components/fluidCanvas/components/canvasApplication/CanvasPreviewApplication'
import { AgencyDTOBrief } from 'types/agencies/agency'
import { ApplicationItem } from 'types/projects/workflow'

interface Props {
  application: ApplicationItem
  agency?: AgencyDTOBrief
  isEditable?: boolean
  preview?: boolean
  templateView?: boolean
  activityId?: string
  isInactive?: boolean
}

export const CanvasApplication = memo(
  ({ agency, preview, application, templateView, activityId, isEditable, isInactive }: Props) => {
    const assignMember = useAssignMember(application.assignUser)
    const showAction = isEditable
    const isDisabled = !showAction || templateView

    const { handleOpenApplication } = useOpenApplication({ application })

    return (
      <>
        {preview ? (
          <CanvasPreviewApplication
            application={application}
            templateView={templateView}
            handleOpenApp={handleOpenApplication}
            assignMember={assignMember}
            activityId={activityId}
          />
        ) : (
          <CanvasEditableApplication
            application={application}
            agency={agency}
            handleOpenApp={handleOpenApplication}
            isDisabled={isDisabled}
            assignMember={assignMember}
            showAction={showAction}
            isInactive={isInactive}
            activityId={activityId}
          />
        )}
      </>
    )
  },
)
