import { WppCard, WppDivider } from '@platform-ui-kit/components-library-react'
import { FC, PropsWithChildren } from 'react'

import { Flex } from 'components/common/flex/Flex'
import { Truncate } from 'components/common/truncate/Truncate'
import { Column } from 'pages/project/components/canvas/utils'
import styles from 'pages/templates/components/templatePhase/TemplatePhase.module.scss'

type Props = PropsWithChildren<{
  column: Column
  index: number
  variant?: 'primary' | 'secondary'
}>

export const TemplatePhase: FC<Props> = ({ column, children, variant }) => {
  const { description } = column

  return (
    <WppCard className={styles.phase} variant={variant} data-testid={`template-phase-id-${column.id}`}>
      <Flex direction="column" gap={4} className={styles.name}>
        <Truncate lines={2} slot="header" title={column.name} type="m-strong" data-testid="template-phase-header">
          {column.name}
        </Truncate>

        <Truncate lines={1} type="xs-body">
          {description}
        </Truncate>

        <WppDivider className={styles.divider} />
        <div />
      </Flex>
      {children}
    </WppCard>
  )
}
