import { WppListItem } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import { useAssignMember } from 'hooks/useAssignMember'
import { ApplicationLogo } from 'pages/components/applicationLogo/ApplicationLogo'
import styles from 'pages/project/components/canvas/components/item/activity/AppActivityItem.module.scss'
import { Calendar } from 'pages/project/components/canvas/components/phase/Calendar'
import { ResponsiblePerson } from 'pages/project/components/canvas/components/phase/ResponsiblePerson'
import { ApplicationItem } from 'types/projects/workflow'

interface Props {
  app: ApplicationItem
  setSelectedAppId: (appId: string | null) => void
}

export const ActivityDetailsModalApp = ({ app, setSelectedAppId }: Props) => {
  const assignMember = useAssignMember(app.assignUser)

  return (
    <WppListItem
      onWppChangeListItem={() => setSelectedAppId(app.id)}
      className={styles.appItem}
      data-testid="activity-details-app-item"
    >
      <Flex
        inline
        direction="column"
        className={styles.logoWorkflow}
        justify="center"
        align="center"
        data-testid="preview-application-logo"
        slot="left"
      >
        <ApplicationLogo logo={app?.logoUrl} />
      </Flex>
      <span slot="label" data-testid="activity-details-app-name">
        {app.name}
      </span>
      {(app.startDate || app.endDate) && (
        <span slot="caption">
          <Calendar startDate={app.startDate} endDate={app.endDate} size="xs-body" hideIcon />
        </span>
      )}
      <div slot="right">
        <ResponsiblePerson assignMember={assignMember} size="xs" data-testid="task-item-assignee" />
      </div>
    </WppListItem>
  )
}
