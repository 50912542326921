import { useMemo } from 'react'

import { Flex } from 'components/common/flex/Flex'
import { PhaseDetailsModalApp } from 'pages/project/components/canvas/components/phase/detailsModal/PhaseDetailsModalApp'
import { ApplicationItem, PhaseItem, PhaseItemType } from 'types/projects/workflow'

interface Props {
  apps: PhaseItem<ApplicationItem>[]
  setSelectedItem: (appId: string | null, type: PhaseItemType) => void
}

export const PhaseDetailsModalApps = ({ apps, setSelectedItem }: Props) => {
  const sortedApps = useMemo(() => apps.sort((a, b) => a.orderNumber - b.orderNumber), [apps])
  return (
    <Flex direction="column" gap={8}>
      {sortedApps.map(app => (
        <PhaseDetailsModalApp app={app} key={app.id} setSelectedItem={setSelectedItem} />
      ))}
    </Flex>
  )
}
