import { MayBeNull } from '@wpp-open/core'
import { useContext, useEffect, useState } from 'react'

import { WrikeContact } from 'api/wrike/fetchers/fetchContactsMyApi'
import { useContactsMyApi } from 'api/wrike/queries/useContactsMyApi'
import { WrikeAuthContext } from 'auth/wrike/WrikeAuthProvider'
import { useDebouncedValue } from 'hooks/useDebouncedValue'

interface Result {
  myContact: MayBeNull<WrikeContact>
  isLoading: boolean
}

export const useCheckMe = () => {
  const { token: wrikeToken } = useContext(WrikeAuthContext)
  const fetchMe = useContactsMyApi()

  const [result, setResult] = useState<Result>({ myContact: null, isLoading: true })
  // need some timeout to let axios interceptor use proper token
  const debouncedToken = useDebouncedValue(wrikeToken, 0)

  useEffect(() => {
    const fetchData = async () => {
      // get rid of API call w/o token
      if (!debouncedToken) {
        setResult({ myContact: null, isLoading: false })
        return
      }

      try {
        const result = await fetchMe()
        const wrikeContacts = result?.data?.data
        const myContact = wrikeContacts?.length ? wrikeContacts[0] : null

        setResult({ myContact, isLoading: false })
      } catch (e) {
        setResult({ myContact: null, isLoading: false })
      }
    }

    fetchData()
  }, [fetchMe, debouncedToken])

  return result
}
