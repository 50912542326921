import { WppButton, WppLabel, WppPill, WppTooltip } from '@platform-ui-kit/components-library-react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { FormInput } from 'components/form/formInput/FormInput'

export const TemplateTagControl = () => {
  const { t } = useTranslation()
  const {
    formState: { errors },
    getValues,
    watch,
    setValue,
  } = useFormContext()

  const tags = watch('tags')

  const handleAddTag = () => {
    if (errors?.tag || errors?.tags || tags.length >= 10) return

    const { tag, tags: oldTags } = getValues()
    if (!tag.length) return

    setValue('tag', '')
    setValue('tags', [...oldTags, tag])
  }

  const handlePillClose = (tag: string) => {
    const { tags } = getValues()
    setValue('tags', [...tags.filter((t: string) => t !== tag)])
  }

  return (
    <Flex direction="column" gap={5}>
      <WppLabel config={{ text: 'Tags' }} htmlFor="tag" optional typography="s-strong" />
      <Flex gap={12}>
        <FormInput
          style={{ flexGrow: 1 }}
          name="tag"
          placeholder="Type template tag"
          data-testid="template-tag-input"
        />
        <WppTooltip
          text={t('modals.save_project_as_template.tooltip_add_tag')!}
          config={{ trigger: tags.length >= 10 ? 'mouseenter' : 'manual', hideOnClick: false }}
        >
          <div>
            <WppButton
              variant="secondary"
              onClick={handleAddTag}
              disabled={tags.length >= 10}
              data-testid="template-tag-add-btn"
            >
              {t('modals.save_project_as_template.btn_add_tag')}
            </WppButton>
          </div>
        </WppTooltip>
      </Flex>
      {!!tags.length && (
        <Flex gap={5} direction="row" wrap="wrap">
          {tags.map((t: string) => (
            <WppPill
              type="display"
              key={t}
              label={t}
              value={t}
              removable={true}
              onWppClose={() => handlePillClose(t)}
              data-testid="template-tag-pill"
            />
          ))}
        </Flex>
      )}
    </Flex>
  )
}
