// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PBqUx{--li-bg-color-hover: transparent;--li-bg-color-active: transparent}.PBqUx::part(item){cursor:auto}.PBqUx::part(label){font-weight:500}.kw0cn{--wpp-typography-color: --wpp-grey-color-900;--wpp-icon-color: --wpp-grey-color-800;display:flex;gap:4px;align-items:center}.EbAxu{color:var(--wpp-primary-color-600);font-weight:400;font-size:12px;line-height:20px}.EbAxu:hover{text-decoration:underline}`, "",{"version":3,"sources":["webpack://./src/pages/components/projectModal/components/integrations/components/WrikeConnect.module.scss"],"names":[],"mappings":"AAAA,OACE,gCAAA,CACA,iCAAA,CAEA,mBACE,WAAA,CAGF,oBACE,eAAA,CAIJ,OACE,4CAAA,CACA,sCAAA,CAEA,YAAA,CACA,OAAA,CACA,kBAAA,CAGF,OACE,kCAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CAEA,aACE,yBAAA","sourcesContent":[".projectInfoItem {\n  --li-bg-color-hover: transparent;\n  --li-bg-color-active: transparent;\n\n  &::part(item) {\n    cursor: auto;\n  }\n\n  &::part(label) {\n    font-weight: 500;\n  }\n}\n\n.externalLink {\n  --wpp-typography-color: --wpp-grey-color-900;\n  --wpp-icon-color: --wpp-grey-color-800;\n\n  display: flex;\n  gap: 4px;\n  align-items: center;\n}\n\n.helpLink {\n  color: var(--wpp-primary-color-600);\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 20px;\n\n  &:hover {\n    text-decoration: underline;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"projectInfoItem": `PBqUx`,
	"externalLink": `kw0cn`,
	"helpLink": `EbAxu`
};
export default ___CSS_LOADER_EXPORT___;
