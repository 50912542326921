import { WppSkeleton, WppTypography } from '@platform-ui-kit/components-library-react'
import { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { Svg404Icon } from 'components/svg/Svg404Icon'
import { SvgAccessDenied } from 'components/svg/SvgAccessDenied'
import { SvgError } from 'components/svg/SvgError'
import styles from 'pages/components/templatePreviewModal/TemplatePreviewModal.module.scss'
import { is400Error, is401Error, is403Error, is404Error, is5xxError } from 'utils/error'

const getErrorObject = (error: unknown): { title: string; description: string; icon: ReactElement } => {
  switch (true) {
    case is400Error(error):
      return {
        title: 'modals.errors.400.title',
        description: 'modals.errors.400.description',
        icon: <SvgError />,
      }
    case is401Error(error):
      return {
        title: 'modals.errors.401.title',
        description: 'modals.errors.401.description',
        icon: <SvgAccessDenied />,
      }
    case is403Error(error):
      return {
        title: 'modals.errors.403.title',
        description: 'modals.errors.403.description',
        icon: <SvgAccessDenied />,
      }
    case is404Error(error):
      return {
        title: 'modals.errors.404.title',
        description: 'modals.errors.404.description',
        icon: <Svg404Icon />,
      }
    case is5xxError(error):
      return {
        title: 'modals.errors.5xx.title',
        description: 'modals.errors.5xx.description',
        icon: <SvgError />,
      }
    default:
      return {
        title: 'modals.errors.default.title',
        description: 'modals.errors.default.description',
        icon: <SvgError />,
      }
  }
}

export const TemplateViewSkeleton = () => (
  <>
    <WppSkeleton slot="header" variant="rectangle" height="35px" width="320px" />
    <Flex direction="column" slot="body" gap={16}>
      <Flex justify="between">
        <WppSkeleton slot="header" variant="rectangle" height="24px" width="300px" />
        <WppSkeleton slot="header" variant="rectangle" height="24px" width="100px" />
      </Flex>
      <WppSkeleton variant="rectangle" height="600px" width="100%" />
    </Flex>
    <Flex slot="actions" gap={12} justify="end">
      <WppSkeleton slot="header" variant="rectangle" height="40px" width="87px" />
      <WppSkeleton slot="header" variant="rectangle" height="40px" width="87px" />
    </Flex>
  </>
)

export const ErrorState = ({ error }: { error: unknown }) => {
  const { t } = useTranslation()
  const errorText = useMemo(() => getErrorObject(error), [error])

  return (
    <Flex slot="body" className={styles.errorWrapper} direction="column" justify="center" align="center" gap={24}>
      {errorText.icon}
      <Flex className={styles.textWrapper} direction="column" justify="center" align="center" gap={8}>
        <WppTypography type="m-strong" data-testid="view-template-modal-error-title">
          {t(errorText.title)}
        </WppTypography>
        <WppTypography
          type="xs-body"
          data-testid="view-template-modal-error-description"
          className={styles.greyColor800}
        >
          {t(errorText.description)}
        </WppTypography>
      </Flex>
    </Flex>
  )
}
