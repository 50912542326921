import {
  WppActionButton,
  WppCard,
  WppIconExternalLink,
  WppSkeleton,
  WppTypography,
  WppTooltip,
  WppIconGear,
} from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { useFetchProjectIntegration } from 'api/projects/queries/useFetchProjectIntegration'
import { Flex } from 'components/common/flex/Flex'
import { SvgWrikeLogo } from 'components/svg/WrikeLogo'
import { useProject } from 'hooks/useProject'
import { showProjectEditModal } from 'pages/components/projectModal/EditProjectModal'
import styles from 'pages/project/components/projectDashboard/coponents/projectIntegration/ProjectIntegration.module.scss'
import { IntegrationDTO } from 'types/projects/Integration'
import { capitalizeFirstLetter } from 'utils/common'

const IntegrationItem = ({ name, type, connectionId, permalink }: IntegrationDTO) => {
  return (
    <Flex key={connectionId} justify="between" align="center" className={styles.item}>
      <Flex gap={8} align="center">
        <SvgWrikeLogo height={32} width={32} />
        <Flex direction="column">
          <WppTypography type="s-strong">{capitalizeFirstLetter(type.toLowerCase())}</WppTypography>
          <WppTypography type="s-body" className={styles.subTitle}>{`${name} ${
            connectionId ? `(${connectionId})` : ''
          }`}</WppTypography>
        </Flex>
      </Flex>

      <WppTooltip text="Open in Wrike" className={styles.menuButton}>
        <WppActionButton className={styles.icon} onClick={() => window.open(permalink, '_blank')}>
          <WppIconExternalLink />
        </WppActionButton>
      </WppTooltip>
    </Flex>
  )
}

export const ProjectIntegration = () => {
  const { t } = useTranslation()
  const { project } = useProject()
  const { data, isLoading } = useFetchProjectIntegration({ params: { projectId: project.id }, enabled: !!project.id })

  if (isLoading) {
    return (
      <Flex>
        <WppSkeleton variant="rectangle" height="224px" width="100%" />
      </Flex>
    )
  }

  if (!data.length) return null

  return (
    <WppCard variant="primary" size="l">
      <Flex justify="between" style={{ marginBottom: '8px' }}>
        <WppTypography slot="header" type="l-strong">
          {t('project.integrations')}
        </WppTypography>

        <WppActionButton
          variant="secondary"
          onClick={() => showProjectEditModal({ project: project, showIntegration: true })}
        >
          <WppIconGear slot="icon-start" />
        </WppActionButton>
      </Flex>

      <Flex direction="column" gap={8}>
        {data.map(el => (
          <IntegrationItem key={el.connectionId} {...el} />
        ))}
      </Flex>
    </WppCard>
  )
}
