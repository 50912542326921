import { WppButton, WppTooltip, WppActionButton, WppIconGear } from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { useAppLaunchErrors } from 'hooks/application/useAppErrors'
import { useOpenApplication } from 'hooks/application/useOpenApplication'
import { useAssignMember } from 'hooks/useAssignMember'
import { useProject } from 'hooks/useProject'
import { useProjectRole } from 'hooks/useProjectRole'
import { t } from 'i18next'
import { showEditAppModal } from 'pages/project/components/canvas/components/item/application/EditAppModal'
import styles from 'pages/project/components/tasks/Tasks.module.scss'
import { ProcessType } from 'types/projects/projects'
import { ActivityItem, ApplicationItem, PhaseItem } from 'types/projects/workflow'
import { isEqualEmails } from 'utils/common'

interface Props {
  application: ApplicationItem
  activityId?: string
  phaseId?: string
  onClose: () => void
}

export const AppDetailsLaunchApp = ({ application, activityId, phaseId, onClose }: Props) => {
  const projectContext = useProject()
  const navigate = useNavigate()
  const { handleOpenApplication } = useOpenApplication({ application })
  const {
    osContext: { userDetails },
  } = useOs()

  const { isOwnerOrGlobalManage } = useProjectRole()

  const assignMember = useAssignMember(application.assignUser)

  const isMeAssignToThisApp = useMemo(
    () => isEqualEmails(userDetails.email, assignMember?.email),
    [userDetails, assignMember],
  )

  const canvasLinearActivityItems = useMemo(() => {
    return Object.values(projectContext.canvas.tasks).filter(item => {
      if ('itemType' in item) {
        return item.itemType === 'activity'
      }
      return false
    }) as PhaseItem<ActivityItem>[]
  }, [projectContext.canvas.tasks])

  const activity =
    activityId && projectContext.project.processType === ProcessType.LINEAR
      ? canvasLinearActivityItems.find(item => item.item.id === activityId)?.item
      : projectContext.fluidData?.items.find(container => container.id === activityId)

  const isMeAssignToThisActivity = useMemo(
    () => activity && isEqualEmails(userDetails.email, activity?.assignUser),
    [activity, userDetails.email],
  )

  const getPhase = useMemo(() => {
    return phaseId ? projectContext.canvas?.columns[phaseId] : null
  }, [phaseId, projectContext.canvas?.columns])

  const isMeAssignToThisPhase = useMemo(
    () => isEqualEmails(userDetails.email, getPhase?.assignUser),
    [getPhase?.assignUser, userDetails.email],
  )

  const canEdit =
    !projectContext.isInactive &&
    (isOwnerOrGlobalManage || isMeAssignToThisApp || isMeAssignToThisActivity || isMeAssignToThisPhase)

  const appLaunchErrors = useAppLaunchErrors(application)
  const showWarning = !!appLaunchErrors.length

  return (
    <Flex justify="between" className={styles.actionsWrapper}>
      {canEdit ? (
        <WppActionButton
          onClick={() => {
            onClose()
            showEditAppModal({ application, onClose: () => navigate(-1) })
          }}
          data-testid="settings-action"
          variant="primary"
        >
          <WppIconGear slot="icon-start" />

          {t('project.item.settings')}
        </WppActionButton>
      ) : (
        <div />
      )}

      <WppTooltip
        config={{ trigger: showWarning ? 'mouseenter' : 'manual', hideOnClick: false }}
        text={t('modals.app_details_modal.launch_button_error')!}
        className={styles.tooltip}
      >
        <WppButton
          variant="primary"
          size="m"
          onClick={handleOpenApplication}
          disabled={showWarning}
          data-testid="app-launch"
        >
          {t('common.btn_launch')}
        </WppButton>
      </WppTooltip>
    </Flex>
  )
}
