import { useCallback } from 'react'

import { useFetchTasksListApi } from 'api/tasks/queries/useFetchTasksListApi'
import { TableInfiniteLoader } from 'components/common/table'
import { useLatestDistinct } from 'hooks/useLatestDistinct'
import { TaskDashboard } from 'types/dashboard/tasksDashboard'
import { TaskStatus, TasksFilter } from 'types/projects/tasks'

interface Props {
  filters: TasksFilter
}

export const useTasksListLoader = ({ filters: { search, dueDateRanges, archived, selectedProjects } }: Props) => {
  const searchStable = useLatestDistinct(search)
  const dueDateRangesStable = useLatestDistinct(dueDateRanges)
  const archivedStable = useLatestDistinct(archived)
  const selectedProjectsStable = useLatestDistinct(selectedProjects)

  const handleFetchTasksList = useFetchTasksListApi()

  const loader: TableInfiniteLoader<TaskDashboard> = useCallback(
    async ({ endRow, startRow }) => {
      const itemsPerPage = endRow - startRow

      const {
        data: { data, paginator },
      } = await handleFetchTasksList({
        text: searchStable,
        statuses: archivedStable
          ? [TaskStatus.IN_PROGRESS, TaskStatus.TO_DO, TaskStatus.ARCHIVED]
          : [TaskStatus.IN_PROGRESS, TaskStatus.TO_DO],
        dueDateRanges: dueDateRangesStable,
        projectIds: selectedProjectsStable,
        itemsPerPage,
        page: endRow / itemsPerPage,
      })

      return {
        data,
        totalRowsCount: paginator.totalItems,
      }
    },
    [handleFetchTasksList, searchStable, archivedStable, dueDateRangesStable, selectedProjectsStable],
  )

  return { loader }
}
