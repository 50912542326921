import { AxiosError } from 'axios'
import { useMemo } from 'react'

import { ActivityDetailsModalContent } from 'pages/project/components/canvas/components/item/activity/detailsModal/ActivityDetailsModalContent'
import { AppDetailsModalContent } from 'pages/project/components/canvas/components/item/application/detailsModal/AppDetailsModalContent'
import { ActivityItem } from 'types/projects/workflow'

export enum ActivityTabsTypes {
  apps = 'apps',
  resources = 'resources',
}

export const activityTabs = [
  { id: ActivityTabsTypes.apps, value: 'modals.activity_details.tabs.apps' },
  { id: ActivityTabsTypes.resources, value: 'modals.activity_details.tabs.resources' },
]

interface Props {
  activity?: ActivityItem | null
  phaseId?: string
  isLoading?: boolean
  isError?: boolean
  appId?: string
  error?: AxiosError<unknown, any> | null
  onClose: () => void
}

export const ActivityDetailsMain = ({ activity, appId, phaseId, isLoading, isError, error, onClose }: Props) => {
  const selectedApplication = useMemo(() => {
    if (!activity) return null

    return activity.items.find(app => app.application.id === appId)
  }, [activity, appId])

  return (
    <>
      isLoading ? (
      {!selectedApplication && !appId && (
        <ActivityDetailsModalContent
          activity={activity}
          phaseId={phaseId}
          isLoading={isLoading}
          isError={isError}
          error={error}
          onClose={onClose}
        />
      )}
      ) : (
      {appId && (
        <AppDetailsModalContent
          application={selectedApplication?.application}
          onClose={onClose}
          isError={isError}
          error={error}
          activityId={activity?.id}
          phaseId={phaseId}
        />
      )}
      )
    </>
  )
}
