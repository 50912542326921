import { TabsChangeEventDetail } from '@platform-ui-kit/components-library'
import {
  WppButton,
  WppTypography,
  WppIconError,
  WppActionButton,
  WppIconChevron,
  WppTabs,
  WppTab,
} from '@platform-ui-kit/components-library-react'
import { HierarchyCustomNodeType } from '@wpp-open/core/types/mapping/common'
import { useOs } from '@wpp-open/react'
import { AxiosError } from 'axios'
import { useCallback, useState, useMemo } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useUpdateProjectApi } from 'api/projects/mutation/useUpdateProjectApi'
import { useIntegrationsApi } from 'api/tenant/queries/useIntegrationsApi'
import { Flex } from 'components/common/flex/Flex'
import { tableActions } from 'components/common/table'
import { Modal } from 'components/surface/modal/Modal'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { TableKey } from 'constants/table'
import { useForm } from 'hooks/form/useForm'
import { useHierarchy } from 'hooks/useHierarchy'
import { useToast } from 'hooks/useToast'
import { WrikeConnect } from 'pages/components/projectModal/components/integrations/components/WrikeConnect'
import { ProjectForm } from 'pages/components/projectModal/components/projectForm/ProjectForm'
import styles from 'pages/components/projectModal/EditProjectModal.module.scss'
import { normalizeProjectFormDate, getInitialProjectFormDate } from 'pages/components/projectModal/utils'
import { modalValidationScheme } from 'pages/dashboard/components/utils'
import { queryClient } from 'providers/osQueryClient/utils'
import { ContextHierarchy, Project, UpdateProjectFormDTO } from 'types/projects/projects'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'
import { is409Error } from 'utils/error'

interface Props extends NiceModalWrappedProps {
  project: Project
  showIntegration?: boolean
  showBackButton?: boolean
}

enum ProjectTab {
  Details = 'details',
  Integrations = 'integrations',
}

const EditProjectModal = ({ isOpen, onClose, onCloseComplete, project, showBackButton, showIntegration }: Props) => {
  const { t } = useTranslation()
  const { showToast } = useToast()
  const [currentTab, setCurrentTab] = useState(showIntegration ? ProjectTab.Integrations : ProjectTab.Details)

  const [showDataLossWarning, setShowDataLossWarning] = useState(false)
  const {
    osContext: { navigationTree },
  } = useOs()
  const [wrikeError, setWrikeError] = useState(null)

  const { hierarchyOrder, getWorkspaceTree } = useHierarchy()

  const { mutateAsync: updateProject } = useUpdateProjectApi()
  const { data: integrations } = useIntegrationsApi({ staleTime: 60 * 1000 })

  const { name, type, description, contextWorkspace, processType, startDate, endDate, contextHierarchy, wrike } =
    project

  const workspaceData = getWorkspaceTree(contextWorkspace)

  const workspaceFormFields = hierarchyOrder.reduce((previousValue, currentValue) => {
    const curr = workspaceData.find(
      el =>
        currentValue.toLowerCase() ===
        (el.type === HierarchyCustomNodeType ? el.customTypeName : el.type)!.toLowerCase(),
    )

    return {
      ...previousValue,
      [currentValue.toLowerCase()]: curr?.azId || '',
    }
  }, {})

  const isWorkspaceValid = contextWorkspace === null || (!!contextWorkspace && !!workspaceData.length)

  const defaultValues = useMemo(
    () => ({
      name,
      type,
      description: description || '',
      processType,
      ...workspaceFormFields,
      dueDate: getInitialProjectFormDate({ startDate, endDate }),
      wrikeProjectId: wrike?.wrikeProjectId ?? '',
      disconnectWrike: false,
    }),
    [name, type, description, processType, workspaceFormFields, startDate, endDate, wrike],
  )

  const form = useForm({ defaultValues, validationSchema: modalValidationScheme(hierarchyOrder) })
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting, isValid },
    getValues,
    trigger,
  } = form

  const onSubmit = handleSubmit(async () => {
    if (!isValid) {
      await trigger()
      return
    }

    await handleSave()
  })

  const handleSave = useCallback(async () => {
    setShowDataLossWarning(false)
    setWrikeError(null)

    const { type, description, processType, dueDate, name, wrikeProjectId, disconnectWrike, ...rest } = getValues()

    const selectedWorkspace = hierarchyOrder.map(order => rest[order as keyof typeof rest] || '') as string[]
    const contextWorkspace = [...selectedWorkspace].reverse().find(el => el !== '') || ''

    const newContextHierarchy: ContextHierarchy[] = selectedWorkspace.map((id, index) => {
      const node = navigationTree.mapping[id]
      return {
        title: hierarchyOrder[index],
        name: node?.name || null,
        value: node?.azId || null,
      } as ContextHierarchy
    })

    try {
      let projectBody: UpdateProjectFormDTO = {
        name: name.trim(),
        type,
        description: description?.trim() || null,
        contextWorkspace: contextWorkspace || null,
        contextHierarchy: newContextHierarchy,
        processType,
        ...normalizeProjectFormDate(dueDate),
      }

      if (wrikeProjectId) {
        projectBody = { ...projectBody, wrikeProjectId, disconnectWrike }
      }

      await updateProject({
        id: project.id,
        project: projectBody,
      })

      await queryClient.invalidateQueries([ApiQueryKeys.PROJECTS_FETCHER])
      await queryClient.invalidateQueries([ApiQueryKeys.PROJECTS_BY_ID])
      await queryClient.invalidateQueries([ApiQueryKeys.PROJECTS_INFINITE])
      await queryClient.invalidateQueries([ApiQueryKeys.PROJECT_INTEGRATION])
      tableActions.reload([TableKey.PROJECT_LIST])

      onClose()
      reset()
    } catch (e: any) {
      console.error(e)
      if (e?.response?.data?.detail?.code?.includes('WRIKE_')) {
        setWrikeError(e)
        return
      }
      const message = is409Error((e as AxiosError)?.response?.status)
        ? t('modals.create_project.toast_error_duplicate')
        : t('modals.create_project.toast_error_common')
      showToast({
        type: 'error',
        message,
      })
    }
  }, [getValues, hierarchyOrder, navigationTree.mapping, updateProject, project.id, onClose, reset, t, showToast])

  const handleTabChange = (event: CustomEvent<TabsChangeEventDetail>) => {
    setCurrentTab(event.detail.value as ProjectTab)
  }

  const isWrikeEnabled = useMemo(() => {
    return integrations?.find(({ type, available }) => type === 'wrike' && available)
  }, [integrations])

  // has integrations step
  const integrationAvailable = useMemo(
    () => (integrations ?? []).filter(integration => integration.available).length > 0,
    [integrations],
  )

  const availableTabs = useMemo(() => {
    const tabs: ProjectTab[] = [ProjectTab.Details]
    if (integrationAvailable) {
      tabs.push(ProjectTab.Integrations)
    }

    return tabs
  }, [integrationAvailable])

  return (
    <>
      <FormProvider {...form}>
        <SideModal
          open={isOpen && !showDataLossWarning}
          formConfig={{ onSubmit }}
          onWppSideModalClose={onClose}
          onWppSideModalCloseComplete={onCloseComplete}
          size="m"
          data-testid="create-project-modal"
        >
          <WppTypography slot="header" type="2xl-heading">
            {showBackButton && (
              <WppActionButton className={styles.backButton} onClick={onClose}>
                <WppIconChevron direction="left" />
              </WppActionButton>
            )}

            {t('modals.edit_project.title')}
          </WppTypography>

          <div slot="body">
            {availableTabs.length > 1 && (
              <WppTabs value={currentTab} className="wpp-spacing-24-bottom" onWppChange={handleTabChange}>
                <WppTab value={ProjectTab.Details}>{t('modals.edit_project.tab.details')}</WppTab>

                {availableTabs.includes(ProjectTab.Integrations) && (
                  <WppTab value={ProjectTab.Integrations} style={{ whiteSpace: 'nowrap' }}>
                    {t('modals.edit_project.tab.integrations')}
                  </WppTab>
                )}
              </WppTabs>
            )}

            {currentTab === ProjectTab.Details && (
              <ProjectForm
                showWarning={!isWorkspaceValid}
                contextHierarchy={isWorkspaceValid ? [] : contextHierarchy}
                showProcessType={true}
              />
            )}

            {currentTab === ProjectTab.Integrations && (
              <Flex direction="column">{isWrikeEnabled && <WrikeConnect wrike={wrike} wrikeError={wrikeError} />}</Flex>
            )}
          </div>

          <Flex slot="actions" justify="end" gap={12}>
            <WppButton variant="secondary" size="m" onClick={onClose}>
              {t('modals.create_project.btn_cancel')}
            </WppButton>
            <WppButton variant="primary" size="m" type="submit" loading={isSubmitting}>
              {t('modals.edit_project.btn_save')}
            </WppButton>
          </Flex>
        </SideModal>
      </FormProvider>

      <Modal open={showDataLossWarning} size="s">
        <WppTypography slot="header" type="xl-heading">
          <Flex gap={8} align="center">
            <WppIconError />
            {t('modals.edit_project.draft_confirmation.title')}
          </Flex>
        </WppTypography>
        <WppTypography slot="body" type="s-body">
          {t('modals.edit_project.draft_confirmation.message')}
        </WppTypography>

        <Flex slot="actions" gap={12} justify="end">
          <WppActionButton onClick={() => setShowDataLossWarning(false)} variant="secondary">
            {t('common.btn_no')}
          </WppActionButton>
          <WppButton onClick={handleSave} size="s" variant="destructive">
            {t('common.btn_yes')}
          </WppButton>
        </Flex>
      </Modal>
    </>
  )
}

export const { showModal: showProjectEditModal } = createNiceModal<Props>(EditProjectModal, 'edit-project-modal')
