import { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useProjectPhaseApi } from 'api/projects/queries/useProjectPhaseApi'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { ActivityDetailsMain } from 'pages/project/components/canvas/components/item/activity/detailsModal/ActivityDetailsMain'
import { AppDetailsModalContent } from 'pages/project/components/canvas/components/item/application/detailsModal/AppDetailsModalContent'
import { PhaseDetailsModalContent } from 'pages/project/components/canvas/components/phase/detailsModal/PhaseDetailsModalContent'
import { createProjectModal } from 'pages/project/utils/createProjectModal'
import { DetailsModalType } from 'types/common/utils'
import { ActivityItem, ApplicationItem, PhaseItem, PhaseItemType } from 'types/projects/workflow'
import { NiceModalWrappedProps } from 'utils/createNiceModal'

export enum PhaseTabsTypes {
  activities = 'activities',
  apps = 'apps',
}

export const phaseTabs = [
  { id: PhaseTabsTypes.activities, value: 'modals.phase_details.tabs.activities' },
  { id: PhaseTabsTypes.apps, value: 'modals.phase_details.tabs.apps' },
]

interface Props extends NiceModalWrappedProps {
  phaseId: string
  activityId?: string
  appId?: string
}

const PhaseDetailsModal = ({ activityId, appId, phaseId, isOpen, onCloseComplete, onClose }: Props) => {
  const { data: phase, isLoading, isError, error } = useProjectPhaseApi({ params: { id: phaseId } })
  const [, setSearchParams] = useSearchParams()

  const { selectedType, selectedId } = useMemo(() => {
    const type = activityId ? PhaseItemType.Activity : appId ? PhaseItemType.Application : null
    const id = activityId || appId || null
    return { selectedType: type, selectedId: id }
  }, [activityId, appId])

  const { phaseActivities, phaseApps } = useMemo(() => {
    const phaseItems = phase?.phaseItems || []

    return phaseItems.reduce(
      (result, item) => {
        if (item.itemType === PhaseItemType.Activity) {
          result.phaseActivities.push(item as PhaseItem<ActivityItem>)
        }

        if (item.itemType === PhaseItemType.Application) {
          result.phaseApps.push(item as PhaseItem<ApplicationItem>)
        }
        return result
      },
      { phaseActivities: [] as PhaseItem<ActivityItem>[], phaseApps: [] as PhaseItem<ApplicationItem>[] },
    )
  }, [phase?.phaseItems])

  const selectedApplication = useMemo(() => {
    if (!phase || selectedType === PhaseItemType.Activity) return null

    return phaseApps.find(app => app.item.id === selectedId)
  }, [phase, phaseApps, selectedId, selectedType])

  const selectedActivity = useMemo(() => {
    if (!phase || selectedType === PhaseItemType.Application) return null

    return phaseActivities.find(activity => activity.item.id === selectedId)
  }, [phase, phaseActivities, selectedId, selectedType])

  const resetStateToDefault = useCallback(() => {
    setSearchParams({
      view: DetailsModalType.PHASE_DETAILS_PREVIEW,
      phaseId,
      ...(selectedType === PhaseItemType.Activity && appId && { activityId }),
    })
  }, [activityId, appId, phaseId, selectedType, setSearchParams])

  const setSelectedItemCallback = useCallback(
    (id: string | null, type: PhaseItemType | null) => {
      setSearchParams({
        view: DetailsModalType.PHASE_DETAILS_PREVIEW,
        phaseId,
        ...(id && { [type === PhaseItemType.Activity ? 'activityId' : 'appId']: id }),
      })
    },
    [phaseId, setSearchParams],
  )

  const selectedModalTestId = useMemo(() => {
    if (activityId && appId) return 'app-details-modal'
    if (activityId) return 'activity-details-modal'
    if (appId) return 'app-details-modal'
    return 'phase-details-modal'
  }, [activityId, appId])

  return (
    <SideModal
      open={isOpen}
      onWppSideModalClose={onClose}
      onWppSideModalCloseComplete={onCloseComplete}
      size="m"
      data-testid={selectedModalTestId}
      withBackButton={!!selectedId}
      onWppSideModalBackButtonClick={resetStateToDefault}
    >
      {!selectedId && (
        <PhaseDetailsModalContent
          phase={phase}
          isLoading={isLoading}
          error={error}
          isError={isError}
          setSelectedItemCallback={setSelectedItemCallback}
          onClose={onClose}
        />
      )}

      {selectedType === PhaseItemType.Activity && (
        <ActivityDetailsMain
          activity={selectedActivity?.item}
          onClose={onClose}
          phaseId={phase?.id}
          appId={appId}
          isLoading={isLoading}
          isError={isError}
          error={error}
        />
      )}

      {selectedType === PhaseItemType.Application && (
        <AppDetailsModalContent
          application={selectedApplication?.item}
          onClose={onClose}
          isLoading={isLoading}
          isError={isError}
          error={error}
          phaseId={phaseId}
        />
      )}
    </SideModal>
  )
}

export const { showModal: showPhaseDetailsModal, useModal: useShowPhaseDetailsModal } = createProjectModal<Props>(
  PhaseDetailsModal,
  'phase-details-modal',
)
