import { useOs } from '@wpp-open/react'
import { useEffect, PropsWithChildren, useState, useContext } from 'react'

import { colliderApi, facadeApi, masterDataApi, projectApi, userDetailsApi } from 'api'
import { WrikeAuthContext } from 'auth/wrike/WrikeAuthProvider'
import config, { API_TYPES } from 'constants/app-config'
import { is401Error, is422Error } from 'utils/error'

const apiInstances = [projectApi, facadeApi, userDetailsApi, masterDataApi, colliderApi]

export const ApiProvider = ({ children }: PropsWithChildren<{}>) => {
  const [isReady, setIsReady] = useState(false)
  const {
    osApi,
    osContext: { tenant },
  } = useOs()

  const { token: wrikeToken, clearToken: clearWrikeToken } = useContext(WrikeAuthContext)

  useEffect(() => {
    const requestInterceptors = apiInstances.map(instance =>
      instance.client.interceptors.request.use(
        instanceConfig => {
          const bearer = osApi.getAccessToken()
          if (bearer) {
            instanceConfig!.headers!.Authorization = `Bearer ${bearer}`
          }

          if (instance.client.defaults.baseURL === config[API_TYPES.WPP_PROJECTS_API]) {
            instanceConfig!.headers!['X-Tenant-Id'] = tenant.id

            // cross-cutting Wrike integration
            // if (instanceConfig.data?.hasOwnProperty('wrikeProjectId')) {
            instanceConfig!.headers!['X-Wrike-Authorization'] = wrikeToken ? `Bearer ${wrikeToken}` : null
            // }
          }

          if (instance.client.defaults.baseURL === config[API_TYPES.WPP_COLLIDER_API]) {
            instanceConfig!.headers!['X-Tenant-Id'] = tenant.id
            instanceConfig!.headers!['X-Wrike-Authorization'] = wrikeToken ? `Bearer ${wrikeToken}` : null
          }

          return instanceConfig
        },
        error => Promise.reject(error),
      ),
    )

    const responseInterceptors = apiInstances.map(instance =>
      instance.client.interceptors.response.use(
        response => response,
        error => {
          if (instance.client.defaults.baseURL === config[API_TYPES.WPP_COLLIDER_API]) {
            const isAuthError = is401Error(error?.response?.status)
            const isWrongTokenError =
              is422Error(error?.response?.status) && error?.response?.data?.detail === 'Wrike token is invalid'
            if (isAuthError || isWrongTokenError) {
              clearWrikeToken()
            }
          }

          return Promise.reject(error)
        },
      ),
    )

    setIsReady(true)

    return () => {
      requestInterceptors.forEach((interceptor, index) => {
        apiInstances[index].client.interceptors.request.eject(interceptor)
      })

      responseInterceptors.forEach((interceptor, index) => {
        apiInstances[index].client.interceptors.response.eject(interceptor)
      })
    }
    // eslint-disable-next-line
  }, [osApi.getAccessToken, wrikeToken, clearWrikeToken])

  return <>{isReady && children}</>
}
