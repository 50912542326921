import {
  WppAvatarGroup,
  WppCard,
  WppDivider,
  WppIconCalendar,
  WppIconEdit,
  WppIconHistory,
  WppListItem,
  WppTooltip,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { HierarchyCustomNodeType } from '@wpp-open/core'
import clsx from 'clsx'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Avatar, getAvatarColor } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import { useHierarchy } from 'hooks/useHierarchy'
import { showProjectEditModal } from 'pages/components/projectModal/EditProjectModal'
import { ProjectManageMenu } from 'pages/dashboard/components/projectManageMenu/ProjectManageMenu'
import styles from 'pages/dashboard/components/projectsCardView/components/projectItem/ProjectListItem.module.scss'
import { ProjectStatusIcon } from 'pages/dashboard/components/projectsCardView/components/projectStatus/ProjectStatusIcon'
import { ProjectTypeTag } from 'pages/dashboard/components/projectTypeTag/ProjectTypeTag'
import { Project, ProjectStatus as Status } from 'types/projects/projects'
import { PhaseStatus } from 'types/projects/workflow'
import { fullName } from 'utils/common'
import { formatDate } from 'utils/dateFormat'
import { routesManager } from 'utils/routesManager'

interface Props {
  project: Project
  dateLocale?: string
}

export const ProjectListItem: FC<Props> = ({ project, dateLocale }) => {
  const { t } = useTranslation()
  const { getWorkspaceTree, hierarchyOrder } = useHierarchy()

  const { owners, owner, createdAt, startDate, endDate, status, contextWorkspace } = project

  const workspaceData = getWorkspaceTree(contextWorkspace)

  const workspaceFields = hierarchyOrder.map(
    hierarchy =>
      workspaceData.find(
        ({ type, customTypeName }) =>
          hierarchy === (type === HierarchyCustomNodeType ? customTypeName : type)!.toLowerCase(),
      )?.name || '',
  )

  const contextItems = workspaceFields.filter(Boolean)

  const firstInProgressPhase = useMemo(
    () =>
      project?.phases
        .sort((a, b) => a.orderNumber - b.orderNumber)
        .find(phase => phase.status === PhaseStatus.IN_PROGRESS),
    [project],
  )
  return (
    <WppCard interactive className={styles.card} data-testid={`dashboard-project-card-${project.id}`}>
      <Link to={routesManager.project.workflow.getURL({ id: project.id })}>
        <Flex direction="column" className={styles.content} gap={8}>
          {!!contextItems.length && (
            <div className={styles.contextWrapper}>
              <WppTooltip className={styles.contextList} text={contextItems.join('・')}>
                {contextItems.map(item => (
                  <WppTypography type="s-body" className={clsx(styles.contextItem, styles.workspace)} key={item}>
                    {item}
                  </WppTypography>
                ))}
              </WppTooltip>
            </div>
          )}

          <WppTypography type="l-strong" data-testid="project-name" className={styles.projectNameContainer}>
            <ProjectStatusIcon status={status} className={styles.status} />
            <span className={styles.projectName}>{project.name}</span>
            {!!firstInProgressPhase && (
              <WppTypography type="s-body" className={clsx(styles.contextItem, styles.workspace)}>
                {firstInProgressPhase?.name}
              </WppTypography>
            )}
          </WppTypography>

          <div className={styles.footer}>
            <WppDivider />
            <Flex direction="row" align="center" gap={12} className={styles.footerContent}>
              {owners?.length === 1 ? (
                <Avatar
                  withTooltip
                  tooltipConfig={{ placement: 'top' }}
                  className={styles.avatar}
                  size="xs"
                  name={fullName(owner?.firstname, owner?.lastname)}
                  src={owner?.avatarUrl ?? ''}
                />
              ) : (
                <WppAvatarGroup
                  onClick={e => {
                    e.preventDefault()
                  }}
                  maxAvatarsToDisplay={4}
                  size="xs"
                  withTooltip
                  users={owners?.map(member => ({
                    name: fullName(member.firstname, member.lastname),
                    src: member.avatarUrl ?? '',
                    color: getAvatarColor({ name: member.firstname }),
                  }))}
                />
              )}
              {(startDate || endDate) && (
                <WppTooltip
                  text={`${t('common.start_date')}: ${startDate ? formatDate(startDate, dateLocale) : '-'} 
                    ${t('common.end_date')}: ${endDate ? formatDate(endDate, dateLocale) : '-'}`}
                >
                  <WppIconCalendar />
                </WppTooltip>
              )}
              {!startDate && !endDate && (
                <WppTooltip text={`${t('common.created_on')}: ${formatDate(createdAt, dateLocale, true)}`}>
                  <WppIconHistory />
                </WppTooltip>
              )}

              <ProjectTypeTag type={project.type} className={styles.projectType} />
            </Flex>
          </div>
        </Flex>
      </Link>
      <ProjectManageMenu
        project={project}
        securedChildren={
          status === Status.ACTIVE && (
            <WppListItem
              onWppChangeListItem={() => showProjectEditModal({ project: project })}
              data-testid="edit-action"
            >
              <WppIconEdit slot="left" />
              <WppTypography slot="label" type="s-body">
                {t('project.list.btn_edit_project')}
              </WppTypography>
            </WppListItem>
          )
        }
      />
    </WppCard>
  )
}
