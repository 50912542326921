import { WppIconWarning, WppTooltip } from '@platform-ui-kit/components-library-react'

import { useAppLaunchErrors } from 'hooks/application/useAppErrors'
import { t } from 'i18next'
import { showAppIssuesModal } from 'pages/project/components/canvas/components/appIssuesModal/AppIssuesModal'
import styles from 'pages/project/components/canvas/components/item/appLaunchWarnings/AppLaunchWarnings.module.scss'
import { ApplicationItem } from 'types/projects/workflow'

interface Props {
  application: ApplicationItem
  className?: string
}

export const AppLaunchWarnings = ({ application, className }: Props) => {
  const appLaunchErrors = useAppLaunchErrors(application)

  if (appLaunchErrors.length) {
    return (
      <WppTooltip
        className={className}
        config={{ maxWidth: 'none' }}
        text={t('product.app_launch_alert')!}
        data-testid="phase-item-warning"
      >
        <WppIconWarning
          className={styles.icon}
          onClick={() =>
            showAppIssuesModal({
              application,
            })
          }
        />
      </WppTooltip>
    )
  }

  return null
}
