import { useOs } from '@wpp-open/react'
import { useCallback } from 'react'

import { useAppLaunchErrors } from 'hooks/application/useAppErrors'
import { useProject } from 'hooks/useProject'
import { NativeAppType } from 'types/products/nativeApp'
import { ApplicationItem } from 'types/projects/workflow'
import { simulateLinkClick } from 'utils/link'

interface Props {
  application: ApplicationItem
}

export const useOpenApplication = ({ application }: Props) => {
  const {
    osApi: { navigation },
  } = useOs()

  const projectContext = useProject()
  const project = projectContext?.project

  const openApp = navigation.openApp
  const appLaunchErrors = useAppLaunchErrors(application)

  const handleOpenApplication = useCallback(async () => {
    const { name } = application

    if (!project) {
      console.warn(`Can't run "${name}" application, project is absent.`)
      return
    }

    const canRunApp = !appLaunchErrors.length
    if (!canRunApp) {
      console.warn(`Can't run "${name}" application, please check app configuration.`)
      return
    }

    if (application.type === NativeAppType.EXTERNAL_LINK) {
      simulateLinkClick({ href: application.externalAppVersions[0].config.url!, target: '_blank' })
      return
    }

    openApp(application.appInstanceId)
  }, [application, appLaunchErrors, openApp, project])

  return { handleOpenApplication }
}
