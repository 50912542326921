import { colliderApi } from 'api'
import { AccessTokenApi } from 'auth/wrike/types'

interface Params {
  code: string
  state: string
  redirectUri: string
}

export const fetchAccessTokenApi = (params: Params) =>
  colliderApi.get<AccessTokenApi>('/wrike/auth/authenticate', { params })
