// Product Category
import { NativeAppsCategory, NativeAppsCommercialModel } from 'types/products/nativeApp'

export const NativeAppsCategoryLabel: Record<NativeAppsCategory, string> = {
  [NativeAppsCategory.MEDIA]: 'Media',
  [NativeAppsCategory.PRODUCTION]: 'Production',
  [NativeAppsCategory.BRAND]: 'Brand',
  [NativeAppsCategory.COMMERCE]: 'Commerce',
  [NativeAppsCategory.CREATIVE]: 'Creative',
  [NativeAppsCategory.CRM]: 'CRM',
  [NativeAppsCategory.PR]: 'PR',
  [NativeAppsCategory.DATA]: 'Data',
  [NativeAppsCategory.OTHER_APPS]: 'Other',
}

export const nativeAppsCategoryOptions = [
  ...Object.entries(NativeAppsCategoryLabel).map(([key, value]) => ({ label: value, value: key })),
]

// Product Commercial model
export const NativeAppsCommercialModelLabel = {
  [NativeAppsCommercialModel.CONTRACT]: 'Contract',
  [NativeAppsCommercialModel.FREE]: 'Free',
  [NativeAppsCommercialModel.PAY_AS_YOU_GO]: 'Pay-as-you-go',
}

export const nativeAppsCommercialModelOptions = [
  ...Object.entries(NativeAppsCommercialModelLabel).map(([key, value]) => ({ label: value, value: key })),
]
