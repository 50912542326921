import { useOs } from '@wpp-open/react'
import { createContext, PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { WrikeAuthPostEvent } from 'auth/wrike/types'
import { readWrikeToken, writeWrikeToken } from 'auth/wrike/utils'
import { useToast } from 'hooks/useToast'

interface Props {
  token: string | null
  clearToken: () => void
}

export const WrikeAuthContext = createContext<Props>(null!)

export const WrikeAuthProvider = ({ children }: PropsWithChildren<{}>) => {
  const {
    osContext: { userDetails },
  } = useOs()
  const { t } = useTranslation()
  const { showToast } = useToast()
  const [token, setToken] = useState(readWrikeToken(userDetails.id))

  useEffect(() => {
    const childResponse = (e: any) => {
      // Webpack also using postMessage, so we need to catch only our own messages so wrike=true is temp solution
      // Also it's important to check origin
      if (e.origin === window.location.origin && e.data?.wrike) {
        const eventData: WrikeAuthPostEvent = e.data
        if (eventData.error) {
          showToast({
            type: 'error',
            message: t('project.wrike.auth_generic_error'),
          })
          console.error('Wrike integration error')
          return
        }

        const newToken = eventData.auth?.accessToken ?? null
        writeWrikeToken(newToken, userDetails.id)
        setToken(newToken)
      }
    }

    window.addEventListener('message', childResponse)

    return () => window.removeEventListener('message', childResponse)
  }, [showToast, t, token, userDetails.id])

  const clearToken = useCallback(() => {
    writeWrikeToken(null, userDetails.id)
    setToken(null)
  }, [userDetails.id])

  return (
    <WrikeAuthContext.Provider
      value={{
        token,
        clearToken,
      }}
    >
      {children}
    </WrikeAuthContext.Provider>
  )
}
