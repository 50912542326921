import { projectApi } from 'api'
import { PaginatedResponse, PaginationParams } from 'api/common/types'
import { TemplateFilter } from 'types/projects/projects'
import { Template } from 'types/projects/template'

export const fetchWorkflowTemplatesListApi = ({
  inputText,
  processType,
  page = 1,
  itemsPerPage = 30,
}: PaginationParams<TemplateFilter>) =>
  projectApi.post<PaginatedResponse<Template>>(
    '/templates/search',
    {
      inputText,
      ...(processType && { 'filter[processType]': [processType] }),
    },
    {
      params: {
        page,
        itemsPerPage,
      },
    },
  )
