import styles from 'components/svg/common.module.scss'

export const SvgError = (props: JSX.IntrinsicElements['svg']) => (
  <svg {...props} width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_74036_1090)">
      <circle cx="60" cy="60" r="60" className={styles.fillPrimary100} />
      <ellipse cx="32.5956" cy="24.0001" rx="2.24989" ry="2.25" className={styles.fillPrimary300} />
      <g opacity="0.36" filter="url(#filter0_f_74036_1090)">
        <path
          d="M55.7574 21.1176C58.1005 18.7745 61.8995 18.7745 64.2426 21.1176L98.8824 55.7574C101.226 58.1005 101.226 61.8995 98.8824 64.2426L64.2426 98.8824C61.8995 101.226 58.1005 101.226 55.7574 98.8824L21.1176 64.2426C18.7745 61.8995 18.7745 58.1005 21.1176 55.7574L55.7574 21.1176Z"
          className={styles.fillPrimary500}
        />
      </g>
      <path
        d="M55.5645 21.3105C58.0142 18.8608 61.9858 18.8608 64.4355 21.3105L98.6895 55.5645C101.139 58.0142 101.139 61.9858 98.6895 64.4355L64.4355 98.6895C61.9858 101.139 58.0142 101.139 55.5645 98.6895L21.3105 64.4355C18.8608 61.9858 18.8608 58.0142 21.3105 55.5645L55.5645 21.3105Z"
        className={styles.fillWhite}
      />
      <rect x="55.5" y="37.5" width="9" height="31.5" rx="4.5" className={styles.fillPrimary500} />
      <path
        d="M60 73.5C57.5147 73.5 55.5 75.5147 55.5 78C55.5 80.4853 57.5147 82.5 60 82.5C62.4853 82.5 64.5 80.4853 64.5 78C64.5 75.5147 62.4853 73.5 60 73.5Z"
        className={styles.fillPrimary500}
      />
      <path
        d="M29.7347 92.0216C30.0672 90.7877 31.8942 90.7877 32.2267 92.0216L32.401 92.6683C32.5217 93.1162 32.8935 93.4618 33.364 93.5636L33.7268 93.642C35.0654 93.9314 35.0654 95.7609 33.7268 96.0503L33.364 96.1288C32.8935 96.2305 32.5217 96.5761 32.401 97.024L32.2267 97.6707C31.8942 98.9047 30.0672 98.9046 29.7347 97.6707L29.5604 97.024C29.4397 96.5761 29.068 96.2305 28.5974 96.1288L28.2347 96.0503C26.8961 95.7609 26.8961 93.9314 28.2347 93.642L28.5974 93.5636C29.068 93.4618 29.4397 93.1162 29.5604 92.6683L29.7347 92.0216Z"
        className={styles.fillPrimary400}
      />
      <path
        d="M92.0032 88.0865C92.2692 87.0994 93.7308 87.0994 93.9968 88.0865L94.1362 88.6039C94.2328 88.9622 94.5302 89.2387 94.9066 89.3201L95.1968 89.3828C96.2677 89.6144 96.2677 91.0779 95.1968 91.3095L94.9066 91.3722C94.5302 91.4536 94.2328 91.7301 94.1362 92.0884L93.9968 92.6058C93.7308 93.593 92.2692 93.593 92.0032 92.6058L91.8638 92.0884C91.7672 91.7301 91.4698 91.4536 91.0934 91.3722L90.8032 91.3095C89.7323 91.0779 89.7323 89.6144 90.8032 89.3828L91.0934 89.3201C91.4698 89.2387 91.7672 88.9622 91.8638 88.6039L92.0032 88.0865Z"
        className={styles.fillPrimary200}
      />
      <ellipse cx="38.5956" cy="105" rx="2.24989" ry="2.25" className={styles.fillPrimary200} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.75 35.0577C24.1307 35.0577 25.25 33.9384 25.25 32.5578C26.6307 32.5578 27.75 33.677 27.75 35.0577C27.75 36.4384 26.6307 37.5576 25.25 37.5576C23.8693 37.5576 22.75 36.4384 22.75 35.0577ZM22.75 35.0577C22.75 33.677 23.8693 32.5578 25.25 32.5578C25.25 31.1771 24.1307 30.0579 22.75 30.0579C21.3693 30.0579 20.25 31.1771 20.25 32.5578C20.25 33.9384 21.3693 35.0577 22.75 35.0577Z"
        className={styles.fillPrimary200}
      />
      <path
        d="M96.2541 34.6755C96.5866 33.4415 98.4136 33.4415 98.7462 34.6755L98.9205 35.3222C99.0412 35.7701 99.4129 36.1157 99.8835 36.2174L100.246 36.2958C101.585 36.5853 101.585 38.4147 100.246 38.7042L99.8835 38.7826C99.4129 38.8843 99.0412 39.2299 98.9205 39.6778L98.7462 40.3245C98.4136 41.5585 96.5866 41.5585 96.2541 40.3245L96.0798 39.6778C95.9591 39.2299 95.5873 38.8843 95.1167 38.7826L94.754 38.7042C93.4153 38.4147 93.4153 36.5853 94.754 36.2958L95.1167 36.2174C95.5873 36.1157 95.9591 35.7701 96.0798 35.3222L96.2541 34.6755Z"
        className={styles.fillPrimary300}
      />
      <circle cx="105.75" cy="43.0386" r="1.5" className={styles.fillPrimary400} />
    </g>
    <defs>
      <filter
        id="filter0_f_74036_1090"
        x="4.36023"
        y="4.36028"
        width="111.28"
        height="111.279"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="7.5" result="effect1_foregroundBlur_74036_1090" />
      </filter>
      <clipPath id="clip0_74036_1090">
        <rect width="120" height="120" rx="60" className={styles.fillWhite} />
      </clipPath>
    </defs>
  </svg>
)
