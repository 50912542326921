import { useHref } from 'react-router-dom'

export function useWrikeRedirectUrl(): string {
  const wrikeRoute = useHref('/wrikeAuth')
  return `${window.origin}${wrikeRoute}`
}

const WRIKE_TOKEN_KEY = 'WPP_WRIKE_TOKEN'
export const readWrikeToken = (userId: string) => {
  return localStorage.getItem(`${WRIKE_TOKEN_KEY}:${userId}`) || null
}

export const writeWrikeToken = (token: string | null, userId: string) => {
  if (token == null) {
    localStorage.removeItem(`${WRIKE_TOKEN_KEY}:${userId}`)
  } else {
    localStorage.setItem(`${WRIKE_TOKEN_KEY}:${userId}`, token)
  }
}
