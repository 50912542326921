import { Flex } from 'components/common/flex/Flex'
import { ActivityDetailsModalApp } from 'pages/project/components/canvas/components/item/activity/detailsModal/ActivityDetailsModalApp'
import { ActivityApplicationItem } from 'types/projects/workflow'

interface Props {
  apps: ActivityApplicationItem[]
  setSelectedAppId: (appId: string | null) => void
}

export const ActivityDetailsModalApps = ({ apps, setSelectedAppId }: Props) => {
  return (
    <Flex direction="column" gap={8}>
      {apps.map(app => (
        <ActivityDetailsModalApp app={app.application} key={app.id} setSelectedAppId={setSelectedAppId} />
      ))}
    </Flex>
  )
}
