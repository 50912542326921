import { colliderApi } from 'api'
import { CancelableRequestProducer } from 'api/common/types'

interface Params {
  redirectUri: string
}

interface AuthLink {
  url: string
}

export const fetchAuthLinkApi = (params: Params): CancelableRequestProducer<AuthLink> => {
  return signal => colliderApi.client.get<AuthLink>('/wrike/auth/link', { signal, params })
}
