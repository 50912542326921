import { WppCard, WppIconExternalLink, WppListItem, WppTypography } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { format, parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/components/projectModal/components/integrations/components/WrikeConnect.module.scss'
import { ProjectWrikeDTO } from 'types/projects/projects'
import { DATE_FORMAT } from 'utils/dateFormat'

export const WrikeProjectCard = ({ wrike }: { wrike?: MayBeNull<ProjectWrikeDTO> }) => {
  const { t } = useTranslation()

  return (
    <WppCard variant="secondary" size="s" className="wpp-spacing-4-top">
      {wrike && (
        <Flex direction="column" gap={4}>
          <Flex justify="between">
            <WppListItem className={styles.projectInfoItem}>
              <span slot="label">{t('project.wrike.project_info.name')}</span>
              <span slot="caption">{wrike.name}</span>
            </WppListItem>

            <Link to={wrike.permalink} className={styles.externalLink} target="_blank" rel="noopener noreferrer">
              <WppTypography type="s-strong">{t('project.wrike.project_info.open')}</WppTypography>
              <WppIconExternalLink />
            </Link>
          </Flex>

          <WppListItem className={styles.projectInfoItem}>
            <span slot="label">{t('project.wrike.project_info.project_id')}</span>
            <span slot="caption">{wrike.wrikeProjectId}</span>
          </WppListItem>

          <WppListItem className={styles.projectInfoItem}>
            <span slot="label">{t('project.wrike.project_info.status_title')}</span>
            <span slot="caption">{t('project.wrike.project_info.status_value')}</span>
          </WppListItem>

          <WppListItem className={styles.projectInfoItem}>
            <span slot="label">{t('project.wrike.project_info.linked_at_title')}</span>
            <span slot="caption">
              {wrike.connectedAt
                ? t('project.wrike.project_info.linked_at_value', {
                    date: format(parseISO(wrike.connectedAt), DATE_FORMAT.DD_LLL_YYYY),
                    time: format(parseISO(wrike.connectedAt), 'HH:mm'),
                  })
                : '-'}
            </span>
          </WppListItem>
        </Flex>
      )}
    </WppCard>
  )
}
