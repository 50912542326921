import { MayBeNull } from '@wpp-open/core'

import { projectApi } from 'api'
import { ContextHierarchy, ProcessType, Project, ProjectStatus, ProjectType } from 'types/projects/projects'

export interface CreateProjectDTO {
  name: string
  type: ProjectType
  processType: ProcessType
  status?: ProjectStatus
  contextWorkspace?: string
  description?: string
  startDate?: MayBeNull<string>
  endDate?: MayBeNull<string>
  templateId?: string
  contextHierarchy?: ContextHierarchy[]
  wrikeProjectId?: string
}

export const createProjectApi = (body: CreateProjectDTO) => projectApi.post<Project>('/projects', body)
