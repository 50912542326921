import * as zod from 'zod'

import { ProjectType } from 'types/projects/projects'

const typeEnum = zod.enum([ProjectType.BLANK, ProjectType.PITCH, ProjectType.CAMPAIGN, ProjectType.WORKSHOP])

export const modalValidationScheme = (otherFields: string[]) =>
  zod
    .object({
      name: zod.string().min(3, 'This field is required and should have at least 3 symbols'),
      type: zod.nativeEnum(typeEnum.enum),
      description: zod.string().max(600, 'Description should have 600 characters at most'),
      dueDate: zod.date().array().optional(),
      ...otherFields.reduce(
        (previousValue, currentValue, currentIndex) => ({
          ...previousValue,
          [currentValue]: currentIndex === 0 ? zod.string().optional() : zod.string(),
        }),
        {},
      ),

      wrikeProjectId: zod.union([
        zod.literal(''),
        zod
          .string()
          .regex(/^\d{10}$/, 'Wrike project ID must have 10 digits.')
          .optional(),
      ]),
    })
    .refine(
      ({ name, description, type, dueDate, ...rest }) =>
        type === ProjectType.BLANK ? true : Object.values(rest).some(Boolean),
      {
        message: `Please enter ${otherFields[0].toLowerCase()} of your project.`,
        path: [otherFields[0]],
      },
    )

type FiltersAmount<T> = {
  [K in keyof T]: T[K][] | undefined | string | boolean
}

export const getAppliedFilters = <T>(filters: FiltersAmount<T>): number => {
  return Object.values(filters)
    .filter(value => {
      if (typeof value === 'boolean') {
        return value
      }
      return Array.isArray(value) && value.length > 0
    })
    .flat().length
}
