import { colliderApi } from 'api'

export interface WrikeContact {
  id: string
  firstName: string
  lastName: string
  primaryEmail: string
}

interface Response {
  data: WrikeContact[]
}
export const fetchContactsMyApi = () => colliderApi.get<Response>('/wrike/api/contacts/my')
